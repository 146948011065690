import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from './base.service';
import { ResultOfT } from '../models/response/result';
import { MigrationClientResponseModel } from '../models/response/migration-client-response';
import { MigrationClientRequestModel } from '../models/request/migration-client-request';
import { ForgotPasswordModel, ResetPasswordModel } from '../models/response/forgot-password';

@Injectable({
  providedIn: 'root'
})
export class MigrationClientService extends BaseService{

  constructor(httpService: HttpClient, private router: Router) {
    super(httpService);
  }

  getAllMigrationClientInfo() {
    return this.get<ResultOfT<MigrationClientResponseModel[]>>('MigrationClientInfo/GetAllMigrationClientInfo');
  }

  getMigrationClientInfo(clientId: number){
    return this.get<ResultOfT<MigrationClientResponseModel>>('MigrationClientInfo/GetMigrationClientInfo/'+clientId);
  }

  addMigrationClient(migrationClient: MigrationClientRequestModel){
    
    return this.post<ResultOfT<any>>('MigrationClientInfo/AddMigrationClientinfo', migrationClient);
  }

  updateMigrationClient(migrationClient: MigrationClientRequestModel){
    return this.post<ResultOfT<any>>('MigrationClientInfo/UpdateMigrationClientInfo', migrationClient);
  }

  deleteMigrationClient(clientId: number){
    return this.get<ResultOfT<boolean>>('MigrationClientInfo/DeleteMigrationCLientInfo/'+clientId);
  }

  resetPasswordForMigrationClient(resetPassword: ResetPasswordModel){
    return this.post<ResultOfT<boolean>>('MigrationClientInfo/ResetPasswordForMigrationClient', resetPassword);
  }

  forgotPassword(forgotPassword: ForgotPasswordModel){
    return this.post<ResultOfT<any>>('MigrationClientInfo/ForgotPassword', forgotPassword);
  }

  sendPasswordResetLink(clientId: number){
    return this.post<ResultOfT<boolean>>('MigrationClientInfo/SendPasswordResetLink/'+clientId, null);
  }

  authenticateForgotPasswordRequest(reqModel: ForgotPasswordModel){
    return this.post<ResultOfT<MigrationClientResponseModel>>('MigrationClientInfo/AuthenticateForgotPasswordRequest', reqModel);
  }

  resetPassword(reqModel: ResetPasswordModel){
    return this.post<ResultOfT<any>>('MigrationClientInfo/ResetPasswordForMigrationClient', reqModel);
  }

  resendPasswordResetLink(clientId: number){
    return this.post<ResultOfT<any>>('MigrationClientInfo/SendPasswordResetLink/'+clientId, null);
  }
}
