import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../shared/constants';
import { CustomValidator } from '../shared/custom-validator';
import { BaseComponent } from '../shared/base.component';
import { Subject } from 'rxjs';
import { UserInfoRequestModel } from '../models/request/userInfo-request';
import { UserService } from '../services/user.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from '../models/response/result';
import { UserInfoResponseModel } from '../models/response/userInfo-response';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { WebStorageService } from '../services/web-storage.service';
import { AccessTokenResponseModel, UserAuthResponseModel } from '../models/response/access-token-response';
import { environment } from 'src/environments/environment';
import { enumDatabaseType, UserRole } from '../shared/enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();

  title = 'DocTainium Cloud';
  h2title = 'Sign In';

  displayMessage: boolean = false;
  message: string = '';
  messageClass: boolean;

  formFields = {
    email: 'email',
    password: 'password',
    rememberMe: 'rememberMe'
  };

  validationMessages = {
    email: {
      required: 'Email is required',
      validemail: 'Email is invalid'
    },
    password: {
      required: 'Password is required',
    }
  };

  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private router: Router, private cookieService: CookieService, private webStorageService: WebStorageService) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    this.loading = true;
    const userInfoReq = new UserInfoRequestModel();
    userInfoReq.email = this.form.get(this.formFields.email).value;
    userInfoReq.password = this.form.get(this.formFields.password).value;
    const rememberMe = this.form.get(this.formFields.rememberMe).value;



    if (rememberMe) {
      const expiredDate = new Date();
      expiredDate.setDate(expiredDate.getDate() + 7);

      this.cookieService.set(Constants.cookie.email, userInfoReq.email, expiredDate);
      this.cookieService.set(Constants.cookie.password, userInfoReq.password, expiredDate);
    }
    else {
      this.cookieService.delete(Constants.cookie.email);
      this.cookieService.delete(Constants.cookie.password);
    }

    this.userService.signIn(userInfoReq)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<UserInfoResponseModel>) => {
        this.processResult<UserInfoResponseModel>(result, async () => {
          if (result.IsSuccess) {
            this.loading = true;
            const userauthmodel = new UserAuthResponseModel();
            userauthmodel.companyId = result?.ResponseModel?.CompanyId;
            userauthmodel.companyName = result?.ResponseModel?.CompanyName;
            userauthmodel.groupId = result?.ResponseModel?.GroupId;
            userauthmodel.userId = result?.ResponseModel?.UserId;
            userauthmodel.role = result?.ResponseModel?.Role;
            userauthmodel.email = result?.ResponseModel?.Email;
            userauthmodel.userFirstName = result?.ResponseModel?.FirstName;
            userauthmodel.userLastName = result?.ResponseModel?.LastName;
            userauthmodel.userName = result?.ResponseModel?.FirstName + " " + result?.ResponseModel?.LastName;
            userauthmodel.group = result?.ResponseModel?.Group
            

            if (result?.ResponseModel?.Role == UserRole.enumCustomer) {
              userauthmodel.isDocuware = result?.ResponseModel?.IsDocuware;
              if (result?.ResponseModel?.Group == null)
                userauthmodel.userPermission = result?.ResponseModel?.UserPermissions;
              else if (result?.ResponseModel?.Group != null)
                userauthmodel.groupPermission = result?.ResponseModel?.GroupPermissions;
            }

            userauthmodel.token = result?.ResponseModel?.token;
            userauthmodel.refreshtoken = result?.ResponseModel?.refreshtoken;
            this.webStorageService.storeCurrentUserToken(userauthmodel);
            // if (userauthmodel.companyId > 0 && userauthmodel.role != 'Admin') {
            //   this.router.navigate(['/customer/groups']);
            // }
            // else {
            //   this.router.navigate(['/company']);
            // }
            if (userauthmodel.role === UserRole.enumAdmin) {
              this.router.navigate(['/company']);
            }
            else {
              // var url = '/company/' + userauthmodel.companyId + '/document-cabinet';
              var url = '/company/' + userauthmodel.companyId + '/configuration/document-filing-cabinets/document-cabinets';
              this.router.navigate([url]);
            }

          } else {
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.timeoutFunct(httpResponseError.message, false);
      });

  }

  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage = true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup() {
    this.displayMessage = false;
  }


  private buildForm() {
    let email = '';
    let password = '';
    let rememberMe = false;
    
    const formGroup = this.formBuilder.group({});
    if (this.cookieService.check(Constants.cookie.email) &&
      this.cookieService.check(Constants.cookie.password)) {
      email = this.cookieService.get(Constants.cookie.email);
      password = this.cookieService.get(Constants.cookie.password);
      rememberMe = true;
    }


    formGroup.addControl(this.formFields.email, this.formBuilder.control(email, { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.password, this.formBuilder.control(password, { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.rememberMe, this.formBuilder.control(rememberMe));
    this.form = formGroup;
  }

}
