
<div class="login-outer">
    <div class="login-outer__block">
      <div class="login-outer__block__box">
        <!--<h1>{{title}}</h1>
  <h2>{{h2title}}</h2>-->
        <!--<h1>{{title}}</h1>-->
        <div class="logo">
          <img src="assets/img/FullLogo_NoBuffer.png" color="white" />
        </div>
        <h2 style="margin-top:5px;">{{h2title}}</h2>
        <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
          <p>{{message}}</p>
          <a class="close-popup" href="javascript:void(0);" (click)="closeMessagePopup()"><img width="20" src="assets/img/close-ic.svg"> </a>
        </div>
        <form [formGroup]="form" autocomplete="off">
          <div class="form__group">
            <p>Enter your registered email address to reset your password.</p>
          </div>
          <div class="form__group mb-25">
            <label class="form__label">Email</label>
            <div class="login-inputuser">
              <input formControlName="email" type="text" class="form__control">
              <span *ngIf="hasError(formFields.email,'required')" class="error">{{validationMessages.email.required}}</span>
              <span *ngIf="hasError(formFields.email,'email')" class="error">{{validationMessages.email.validemail}}</span>
            </div>
          </div>
          <div class="form__group mb-0 button-block button-block-new">
            <div class="forgot-password"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/login">Back to Login</a></div>
            <button class="btn-primary" type="submit" (click)="onSubmit()">Submit</button>
          </div>
        </form>
      </div>
    </div>
</div>
