import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { UserRole } from 'src/app/shared/enum';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.sass']
})
export class ConfigurationComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  companyId: number;
  isCustomer: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private webStorageService: WebStorageService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.isCustomer = this.webStorageService.getCurrentUserToken()?.role === UserRole.enumCustomer ? true : false;
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngOnInit(): void {
    this.activatedRoute.params
    .pipe(takeUntil(this.unsubscriber))
    .subscribe((params: Params) => {
      this.companyId = params['id'];
    });
  }

  onCompanyChangeInHeader(company: any) {
    
    var routerUrl = '/company/' + company.CompanyId + '/configuration/document-filing-cabinets/document-cabinets';
    this.router.navigateByUrl(routerUrl);
    // this.router.navigate([routerUrl], { relativeTo: this.activatedRoute }); // when working with same route but different query params add 'this.router.routeReuseStrategy.shouldReuseRoute = () => false;' in constructor 
  }

}
