import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserAuthResponseModel } from '../models/response/access-token-response';
import { ResultOfT } from '../models/response/result';
import { UserInfoResponseModel } from '../models/response/userInfo-response';
import { WebStorageService } from '../services/web-storage.service';
import { UserRole } from '../shared/enum';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private webStorageService: WebStorageService, private jwtHelper: JwtHelperService, private router: Router
    , private http: HttpClient) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) //: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    
    const currentUserToken = this.webStorageService.getCurrentUserToken()
    if (currentUserToken) {

      const token = currentUserToken.token;
      if (token && !this.jwtHelper.isTokenExpired(token)) {
        // console.log(this.jwtHelper.decodeToken(token))
        return true;
      }

      const isRefreshSuccess = await this.tryRefreshingTokens(token);
      if (!isRefreshSuccess) {
        this.router.navigate(['/login']);
      }
      return isRefreshSuccess;

    } else {
      // return false;
      this.router.navigate(['/login']);
    }
  }

  private async tryRefreshingTokens(token: string): Promise<boolean> {
    // debugger
    const usertoken = this.webStorageService.getCurrentUserToken();
    if (!usertoken.token || !usertoken.refreshtoken) {
      return false;
    }

    const credentials = JSON.stringify({ accessToken: usertoken.token, refreshToken: usertoken.refreshtoken });
    let isRefreshSuccess: boolean;
    var url = environment.apiBaseUrl + "token/refresh";
    const refreshRes = await new Promise<ResultOfT<UserInfoResponseModel>>((resolve, reject) => {
      this.http.post<ResultOfT<UserInfoResponseModel>>(url, credentials, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }).subscribe({
        next: (res: ResultOfT<UserInfoResponseModel>) => resolve(res),
        error: (_) => { reject; isRefreshSuccess = false; }
      });
    });
    if (refreshRes.IsSuccess) {
      this.webStorageService.deleteCurrentUserToken();
      const userauthmodel = new UserAuthResponseModel();
      userauthmodel.companyId = refreshRes?.ResponseModel?.CompanyId;
      userauthmodel.companyName = refreshRes?.ResponseModel?.CompanyName;
      userauthmodel.groupId = refreshRes?.ResponseModel?.GroupId;
      userauthmodel.userId = refreshRes?.ResponseModel?.UserId;
      userauthmodel.role = refreshRes?.ResponseModel?.Role;
      userauthmodel.email = refreshRes?.ResponseModel?.Email;
      userauthmodel.userFirstName = refreshRes?.ResponseModel?.FirstName;
      userauthmodel.userLastName = refreshRes?.ResponseModel?.LastName;
      userauthmodel.userName = refreshRes?.ResponseModel?.FirstName + " " + refreshRes?.ResponseModel?.LastName;
      userauthmodel.group = refreshRes?.ResponseModel?.Group
      
      if (refreshRes?.ResponseModel?.Role == UserRole.enumCustomer) {
        userauthmodel.isDocuware = refreshRes?.ResponseModel?.IsDocuware;
        if (refreshRes?.ResponseModel?.Group == null)
          userauthmodel.userPermission = refreshRes?.ResponseModel?.UserPermissions;
        else if (refreshRes?.ResponseModel?.Group != null)
          userauthmodel.groupPermission = refreshRes?.ResponseModel?.GroupPermissions;
      }

      userauthmodel.token = refreshRes?.ResponseModel?.token;
      userauthmodel.refreshtoken = refreshRes?.ResponseModel?.refreshtoken;
      this.webStorageService.storeCurrentUserToken(userauthmodel);
    }
    isRefreshSuccess = true;
    return isRefreshSuccess;
  }

}
