import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'
import { SortEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserAuthResponseModel } from 'src/app/models/response/access-token-response';
import { CompanyResponseModel } from 'src/app/models/response/company-response';
import { ResultOfT } from 'src/app/models/response/result';
import { CompanyService } from 'src/app/services/company.service';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { enumCompanyStatus, enumDatabaseType, UserRole } from 'src/app/shared/enum';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../shared/base.component';


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.sass']
})
export class CompanyComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  @ViewChild('searchBox', { static: false }) searchBox: ElementRef;

  companies: CompanyResponseModel[];  
  companyBackup: CompanyResponseModel[];
  // activeCompanies: CompanyResponseModel[];
  // inactiveCompanies: CompanyResponseModel[];
  
  enumDatabaseType = enumDatabaseType;
  enumCompanyStatus: enumCompanyStatus;
  currentUserToken: UserAuthResponseModel;
  isCustomerAdmin: boolean = false;
  companyId: number;
  selectedCompanyId: number;
  companyCount: number = 0;

  // AccountsActive: boolean = true;
  searchText: string;
  
  noRecordFound: boolean = false;

  displayMessage: boolean;
  messageClass: boolean;
  message: any;
  showClearFilter: boolean = false;

  statusList : any;
  interfaceList: any;
  selectedCompany: CompanyResponseModel;
  selectedInterface: string;
  selectedStatus: string;
  filterResposniveEvent: any;
  ManageCompany: boolean;
  displayClearButton: boolean= true;
  DeletePopup: boolean = false; 
  deleteCompany: CompanyResponseModel;

  constructor( private companyService: CompanyService, private webStorageService: WebStorageService,
  private router: Router) {
    super();
    
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
    this.companyId = this.currentUserToken.companyId;
   }

   filterClickEvent(){
    this.filterResposniveEvent = !this.filterResposniveEvent;       
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngOnInit(): void {
    
    this.companies = new Array<CompanyResponseModel>();
    this.selectedCompanyId =0;

    this.statusList = Object.keys(enumCompanyStatus).map(key => ({label: enumCompanyStatus[key], value: key}));
    this.statusList = this.statusList.filter(x=> x.label !== enumCompanyStatus.enumNone);
    
    this.interfaceList = Object.keys(enumDatabaseType).map(key => ({ label: enumDatabaseType[key], value: key}));
    

    this.populateCompanyList();

    $(document).on('click', function () {
      $('.dropdown').next().removeClass('active');
      $('.search-box').removeClass('active');
      $('.dropdown1').next().removeClass('active');
    });
    
    if ($('.dropdown').length){
      const dropdown = $('.dropdown');
      dropdown.on('click', function (e) {
        e.stopPropagation();
        if (!dropdown.next().hasClass('active')) {
          dropdown.next().addClass('active');
        } else {
          dropdown.next().removeClass('active');
        }
      });
    }

    if ($('.dropdown1').length){
      const dropdown = $('.dropdown1');
      dropdown.on('click', function (e) {
        e.stopPropagation();
        if (!dropdown.next().hasClass('active')) {
          dropdown.next().addClass('active');
        } else {
          dropdown.next().removeClass('active');
        }
      });
    }
    
    $(function(){
      if ($('.search-box').length) {
        const search = $('.search-box');
        search.on('click', function (e) {
          e.stopPropagation();
          if (!search.hasClass('active')) {
            search.addClass('active');
          } else {
            //search.removeClass('active');
          }
        });
      }

    });

  if(this.isCustomerAdmin) {
      $('.title-block__box').css('display','none');
  } 

  }

  populateCompanyList() {
    if(this.currentUserToken.role === UserRole.enumCustomerAdmin){
      this.isCustomerAdmin = true;
      this.getCompanyById(this.currentUserToken.companyId);
    }
    else if(this.currentUserToken.role === UserRole.enumAdmin) {
      this.getAllCompanies();
    } 
    else {
      this.router.navigate(['/404']);
    }
  }

  closeMessagePopup() {
    this.displayMessage = false;
  }

  getAllCompanies() {
    
    this.loading = true;
    this.companyService.getAllCompanies()
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<CompanyResponseModel[]>) => {
      this.processResult<any>(result, () => {
        if (result.IsSuccess) {
          
          if (result.ResponseModel) {

            this.companies = result?.ResponseModel;            

            this.companyBackup = this.companies;

            if(this.companies) {
              this.selectedStatus = enumCompanyStatus.enumActive;
              this.searchByStatusAndInterface('', this.companies);
            }

          }

        }
        else {
          this.loading = false;
          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.loading = false;
      this.timeoutFunct(httpResponseError.message, false);
    });
  }

  private getCompanyById(companyId: number) {
    if (!companyId) {
      this.timeoutFunct('No company found', false);
      return;
    }
    this.loading = true;

    this.companyService.getCompanyById(companyId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<CompanyResponseModel>) => {
        this.processResult<CompanyResponseModel>(result, () => {
          if(result.IsSuccess){
            if(result.ResponseModel){
              this.companies.push(result.ResponseModel);
              if(result.ResponseModel.InterfaceWith !== enumDatabaseType.enumDocuware) {
                this.getStorageByCompanyId(this.currentUserToken.companyId);
              }
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  private getStorageByCompanyId(companyId: number) {
    if (!companyId) {
      this.timeoutFunct('No company found', false);
      return;
    }
    this.loading = true;

    this.companyService.getStorageInfoByCompanyId(companyId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<string>) => {
        this.processResult<string>(result, () => {
          if(result.IsSuccess){
            if(result.ResponseModel){
              if(result.ResponseModel !== '') {
                this.timeoutFunct(result.ResponseModel, false);
              }

            }
            this.loading = false;
          } else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });
  }


  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage =true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  resetFilter (){
    this.selectedStatus = null;
    this.selectedCompany = null;
    this.selectedInterface = null;
    this.showClearFilter = false;
    this.companies = this.companyBackup;
    this.displayClearButton = false;
  }

  onSearchChange(searchText){
    
    var searchText = searchText.toLowerCase();
    if(this.selectedCompany || this.selectedInterface || this.selectedStatus){
      this.displayClearButton = true;
    } else {
      this.displayClearButton = false;
    }

    if(this.selectedCompany) {
      this.companies = this.companyBackup.filter(where => where.CompanyName === this.selectedCompany.CompanyName);
      this.searchByStatusAndInterface(searchText, this.companies)
    } else {
      this.searchByStatusAndInterface(searchText, this.companyBackup);
    }

  }
  

  searchByStatusAndInterface(searchText, companies:any) {
    if(this.selectedStatus == enumCompanyStatus.enumActive) {
      if (searchText.length > 0) {
        if(this.selectedInterface) {
          this.companies = companies.filter(where => where.IsActive == true && where.InterfaceWith === this.selectedInterface && 
            (where.ContactFirstName.toLowerCase().includes(searchText) || where.ContactLastName.toLowerCase().includes(searchText) 
            || where.CompanyName.toLowerCase().includes(searchText) || where.ContactEmail.toLowerCase().includes(searchText)));

            this.noRecordFound = this.companies.length < 1 ? true : false ;
        } else {
          this.companies = companies.filter(where => where.IsActive == true &&
            (where.ContactFirstName.toLowerCase().includes(searchText) || where.ContactLastName.toLowerCase().includes(searchText) 
            || where.CompanyName.toLowerCase().includes(searchText) || where.ContactEmail.toLowerCase().includes(searchText)));

            this.noRecordFound = this.companies.length < 1 ? true : false ;
        }
      } 
      else {
        if(this.selectedInterface){
          this.companies = companies.filter(where => where.IsActive == true && where.InterfaceWith === this.selectedInterface);

            this.noRecordFound = this.companies.length < 1 ? true : false ;
        } else {
          this.companies = companies.filter(where => where.IsActive == true );

          this.noRecordFound = this.companies.length < 1 ? true : false ;
        }
      }

    } if(this.selectedStatus == enumCompanyStatus.enumInactive) {
      if (searchText.length > 0) {
        if (this.selectedInterface) {
          this.companies = companies.filter(where => where.IsActive == false && where.InterfaceWith === this.selectedInterface &&
            (where.ContactFirstName.toLowerCase().includes(searchText) || where.ContactLastName.toLowerCase().includes(searchText)
              || where.CompanyName.toLowerCase().includes(searchText) || where.ContactEmail.toLowerCase().includes(searchText)));

          this.noRecordFound = this.companies.length < 1 ? true : false;
        } else {
          this.companies = companies.filter(where => where.IsActive == false &&
            (where.ContactFirstName.toLowerCase().includes(searchText) || where.ContactLastName.toLowerCase().includes(searchText)
              || where.CompanyName.toLowerCase().includes(searchText) || where.ContactEmail.toLowerCase().includes(searchText)));

          this.noRecordFound = this.companies.length < 1 ? true : false;
        }
      } else {
        if(this.selectedInterface){
          this.companies = companies.filter(where => where.IsActive == false && where.InterfaceWith === this.selectedInterface);

          this.noRecordFound = this.companies.length < 1 ? true : false;
        } else {
          this.companies = companies.filter(where => where.IsActive == false);

          this.noRecordFound = this.companies.length < 1 ? true : false;
        }
      }
    } 
    if(this.selectedStatus == null || this.selectedStatus == ''){
      if (searchText.length > 0) {
        if(this.selectedInterface){
          this.companies = companies.filter(where => where.InterfaceWith === this.selectedInterface && 
            (where.ContactFirstName.toLowerCase().includes(searchText) || where.ContactLastName.toLowerCase().includes(searchText) 
            || where.CompanyName.toLowerCase().includes(searchText) || where.ContactEmail.toLowerCase().includes(searchText)));

            this.noRecordFound = this.companies.length < 1 ? true : false ;
        } else {
          this.companies = companies.filter(where => (where.ContactFirstName.toLowerCase().includes(searchText) || where.ContactLastName.toLowerCase().includes(searchText) 
            || where.CompanyName.toLowerCase().includes(searchText) || where.ContactEmail.toLowerCase().includes(searchText)));

            this.noRecordFound = this.companies.length < 1 ? true : false ;
        }
      }
      else {
        if(this.selectedInterface){
          this.companies = companies.filter(where => where.InterfaceWith.toLowerCase() === this.selectedInterface.toLowerCase());

            this.noRecordFound = this.companies.length < 1 ? true : false ;
        } else {
          this.companies = companies;
        }
      }
    }
  }

  viewCompanyConfig(company: any) {
    var routerUrl;
    if(company?.InterfaceWith === enumDatabaseType.enumInApp) {
      routerUrl = '/company/'+ company.CompanyId + '/inbox';
    } 
    else {
      routerUrl = '/company/'+ company.CompanyId + '/configuration/document-filing-cabinets/document-cabinets';
    }
    this.router.navigateByUrl(routerUrl);
  }

  openDocumentDeletePopup(company: any) {
    this.DeletePopup = true;
    this.deleteCompany = company;
  }
  
  closeDeletePopup() {
    // $('.add-update-popup').fadeOut('fast');
    this.DeletePopup = false;
    this.deleteCompany = new CompanyResponseModel;
  }
  
  onDeleteCompany() {
    if(!this.deleteCompany) {
      return;
    }

    this.companyService.deleteCompany(this.deleteCompany.CompanyId)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<boolean>) => {
      this.processResult<any>(result, () => {
        if (result.IsSuccess) {
          
          if (result.ResponseModel) {
            this.timeoutFunct( this.deleteCompany.CompanyName +' deleted successfully.', true);
            this.getAllCompanies();
            this.closeDeletePopup();
          }
        }
        else {
          if(result.Message.includes('Can not delete company cabinet')) {
            this.timeoutFunct(result.Message + ' ' + this.deleteCompany.CompanyName, false);
          } else {
            this.timeoutFunct(result.Message, false);
          }
          this.loading = false;
          this.closeDeletePopup();
        }
      });
    }, (httpResponseError) => {
      this.loading = false;
      this.closeDeletePopup();
      this.timeoutFunct(httpResponseError.message, false);
    });
  }

  customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null)
              result = -1;
          else if (value1 != null && value2 == null)
              result = 1;
          else if (value1 == null && value2 == null)
              result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
              result = value1.localeCompare(value2);
          else
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

          return (event.order * result);
      });
  }

  onEdit(companyId){
    this.router.navigate(['/company/edit/', companyId]);
  }

  documentCabinetNavigate(companyId){
    this.router.navigate(['document-cabinet/', companyId]);
  }

  onAddIconClick(companyID: number) {
    this.ManageCompany = true;
    
    this.selectedCompanyId = companyID;
    this.companyCount ++;
    $('.add-update-popup').fadeIn('fast');

    $('.page-wrapper').removeClass('active-sidebar')
  }


  companyListUpdated(event: any){
    
    this.populateCompanyList();
  }

  
  ClosePopup(event: any) {
    this.ManageCompany = false;  
    this.searchText = '';  
  }

}
