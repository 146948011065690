import { FormGroup } from '@angular/forms';
import { ResultOfT } from '../models/response/result';
import { HttpErrorResponse } from '@angular/common/http';

export abstract class BaseComponent {

  loading: boolean;
  form: FormGroup;
  noRecordsFoundMessage = 'No records found';

  constructor() {

  }

  processResult<T>(result: ResultOfT<T>, onSuccess: () => void = null, onError: () => void = null) {
  // processResult<T>(result: any, onSuccess: () => void = null, onError: () => void = null) {
    // To handle when request is cancelled
    if (!result) {
      return;
    }

    // if (result.isSuccess) {
    if (result.IsSuccess) {
      if (onSuccess) {
        onSuccess();
      }
    }
    // else if(result.Status = 'Failure' ){
    //   onSuccess();
    // }

    else {
      onSuccess();
      // if (onError) {
      //   onError();
      // }
      // else {
      //   // if (this.messageService) {
      //   //   this.loading = false;
      //   //   this.showErrorMessage(result.message);
      //   // }
      //   setTimeout(() => {
      //     alert(result.Message);
      //   }, 60);
      // }
    }
  }


  markFormAsTouched() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
  }

  hasError(controlName: string, validator: string) {
    const control = this.form.get(controlName);
    if (!control) {
      return false;
    }

    return (control.touched || control.dirty) && control.hasError(validator);
  }

  getErrorMessage(httpResponseError: HttpErrorResponse) {
    if (httpResponseError.error && httpResponseError.error.exceptionMessage) {
      return httpResponseError.error.exceptionMessage;
    }

    return httpResponseError.message;
  }
}
