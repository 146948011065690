import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../shared/constants';
import { CustomValidator } from '../shared/custom-validator';
import { BaseComponent } from '../shared/base.component';
import { ForgotPasswordModel, ResetPasswordModel } from '../models/response/forgot-password';
import { UserService } from '../services/user.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResultOfT } from '../models/response/result';
import { Subject } from 'rxjs';
import { UserInfoResponseModel } from '../models/response/userInfo-response';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  title = 'DocTainium Cloud';
  h2title = 'Reset Password';
  queryString: string;

  displayMessage: boolean = false;
  message: string ='';
  messageClass: boolean;

  forgotPasswordRequestModel: ForgotPasswordModel;
  resetPasswordModel: ResetPasswordModel;
  displayForm: boolean;

  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private router: Router, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  formFields =
  {
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword'
  };

  validationMessages = {
    password: {
      required: 'Password is required',
      minlength: 'Password should be at least 8 characters',
      invalid: 'Enter valid password'
    },
    confirmPassword: {
      required: 'Confirm password is required',
      invalid: 'Enter valid confirm password',
      minlength: 'Password should be at least 6 characters',
      validPasswordMatch: 'Password doesn\'t match'
    }
  };


  ngOnInit(): void {

    this.forgotPasswordRequestModel = new ForgotPasswordModel();
    this.resetPasswordModel = new ResetPasswordModel();

    this.activatedRoute.params
    .pipe(takeUntil(this.unsubscriber))
    .subscribe((params: Params) => {
      this.queryString = params['queryString'];
    });

    if(this.queryString) {
      this.forgotPasswordRequestModel.encryptedString = this.queryString + "=";
    }
     // this.buildForm();

    this.authenticatePaswordChangeRequest();
  }

  private authenticatePaswordChangeRequest() {

    this.userService.authenticateForgotPasswordRequest(this.forgotPasswordRequestModel)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<UserInfoResponseModel>) => {
      this.processResult<any>(result, () => {
        if (result.IsSuccess) {
          if (result.ResponseModel) {
            this.resetPasswordModel.userId = result?.ResponseModel?.UserId;
            this.resetPasswordModel.email = result?.ResponseModel?.Email;
            this.displayForm = true;

            this.buildForm();
          }

        }
        else {
          this.displayForm = false;
          this.loading = false;
          this.message = result.Message;
          // this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.loading = false;
      this.timeoutFunct(httpResponseError.message, false);
    });
  }

  onSubmit() {
    
    if (this.form.invalid)
    {
      this.markFormAsTouched();
      return;
    }

    this.resetPasswordModel.password = this.form.get(this.formFields.newPassword).value;

    this.userService.resetPassword(this.resetPasswordModel)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<any>) => {
      this.processResult<any>(result, () => {
        if (result.IsSuccess) {
          if (result.ResponseModel) {
            this.timeoutFunct("Password updated successfully", true);
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, environment.messageLifeSpan);
          }

        }
        else {
          this.loading = false;
          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.loading = false;
      this.timeoutFunct(httpResponseError.message, false);
    });

  }

  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage =true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup(){
    this.displayMessage = false;
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.newPassword, this.formBuilder.control('', { validators: [Validators.required, Validators.minLength(8), CustomValidator.whitespace, CustomValidator.passwordPattern],
                                                                  updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.confirmPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.passwordPattern],
                                                                          updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.setValidators(CustomValidator.passwordMatch('newPassword', 'confirmPassword'));
    this.form = formGroup;
  }

}
