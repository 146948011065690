import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '../shared/custom-validator';
import { Constants } from '../shared/constants';
import { BaseComponent } from '../shared/base.component';
import { ForgotPasswordModel } from '../models/response/forgot-password';
import { UserService } from '../services/user.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from '../models/response/result';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'DocTainium Cloud';
  h2title = 'Forgot Password?';

  displayMessage: boolean = false;
  message: string ='';
  messageClass: boolean;

  private unsubscriber = new Subject<boolean>();

  formFields = {
    email: 'email',
  };

  validationMessages = {
    email: {
      required: 'Email is required',
      validemail: 'Email is invalid'
    }
  }

  constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }
  onSubmit(){
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    const forgotPassword = new ForgotPasswordModel();
    forgotPassword.email = this.form.get(this.formFields.email).value;

    this.userService.forgotPassword(forgotPassword)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<any>) => {
      this.processResult<any>(result, async () => {
        
        if (result.IsSuccess) {
          // this.timeoutFunct("Password successfully sent on your email address.", true);
          this.timeoutFunct("Link to reset password is successfully sent to your email address.", true);
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, environment.messageLifeSpan);
        }else {
          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.timeoutFunct(httpResponseError.message, false);
    });
  }

  timeoutFunct(msg,  isSuccess: boolean) {
    this.displayMessage =true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup(){
    this.displayMessage = false;
  }


  private buildForm(){
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.email, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = formGroup;
  }
}
