import { Component, OnDestroy, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CompanyResponseModel } from 'src/app/models/response/company-response';
import { ResultOfT } from 'src/app/models/response/result';
import { CompanyService } from 'src/app/services/company.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { enumDatabaseType, UserRole } from 'src/app/shared/enum';
import { UserAuthResponseModel } from 'src/app/models/response/access-token-response';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})

export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() companyFromHeader = new EventEmitter<CompanyResponseModel>();
  @Input('companyId') companyId: number;
  @ViewChild('dropdownParent') dropdownparent: any;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  status: boolean;
  companies: CompanyResponseModel[];
  selectedHeaderCompany: CompanyResponseModel;
  displayMessage: boolean;
  messageClass: boolean;
  message: any;
  isCompanyPage: boolean = false;
  currentUserToken: UserAuthResponseModel;
  companyName: string;
  enumUserRole: UserRole;
  isCustomerAdmin: boolean = false;
  isDocuware: boolean = false;


  constructor(private companyService: CompanyService, private router: Router, private activatedRoute: ActivatedRoute, private webStorageService: WebStorageService) {
    super();
  }
  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.companyName = '';
    this.companies = new Array<CompanyResponseModel>();
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
   

    if (this.router.url !== '/company' && this.router.url !== '/my-profile' && this.router.url !== '/change-password' && this.router.url !== '/docuware-migration-subscription') {
      this.isCompanyPage = false;
      await this.getAllCompanies();
    } else {

      this.companyId = this.currentUserToken.companyId;
      this.isCompanyPage = true;
    }

    if (this.currentUserToken.role === UserRole.enumCustomerAdmin) {
      this.companyName = this.currentUserToken.companyName;
      this.isCustomerAdmin = true;
    }

    if (this.companyId !== 0) {
      
      // var x  = this.companies.filter(x => x.CompanyId == this.companyId);
      // console.log(this.companies);
      
    }

    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.companyId = params['id'];
      });

    $(document).on('click', function () {
      $('.custome-dropdownmenu').next().removeClass('active');
      $('.custome-dropdownmenu').removeClass('active');
    });

    if ($('.custome-click').length) {
      const dropdown = $('.custome-click');
      dropdown.on('click', function (e) {
        e.stopPropagation();
        if (!dropdown.next().hasClass('active')) {
          dropdown.next().addClass('active');
        } else {
          dropdown.next().removeClass('active');
        }
      });
    }

  }

  isActive() {
    const createdURL = 'company/' + this.companyId + '/configuration/';
    return this.router.url.includes(createdURL);
  }


  async getAllCompanies() {
    
    this.companyService.getAllCompanies()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<CompanyResponseModel[]>) => {
        this.processResult<any>(result, () => {
          if (result.IsSuccess) {
            
            if (result.ResponseModel) {
              this.companies = result?.ResponseModel;
              if (this.companies.length > 0 && this.companyId) {
                this.companies = this.companies.sort((a, b) => {
                  return a.CompanyName.toLowerCase().localeCompare(b.CompanyName.toLowerCase());
                });
                this.selectedHeaderCompany = new CompanyResponseModel();
                this.selectedHeaderCompany = this.companies.find(x => x.CompanyId == this.companyId);

                if (this.selectedHeaderCompany?.InterfaceWith === enumDatabaseType.enumDocuware) {
                  this.isDocuware = true;
                }
              }

              if (this.currentUserToken.role === UserRole.enumCustomerAdmin) {
                this.companies = this.companies.filter(x => x.CompanyId === this.currentUserToken.companyId);
              }
            }

          }
          else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  onCompanyChange(event: any) {
    
    this.companyFromHeader.emit(event.value);
  }

  closeParent(event) {
    this.dropdownparent.hide();
  }

  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage = true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

}
