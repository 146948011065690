import { Component, OnInit, OnDestroy, PipeTransform, Pipe, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../shared/base.component';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from '../models/response/result';
import { DocuwareCabinetReuestModel, DocuwareFieldData } from '../models/request/cabinet-request';
import { DownloadFile, ForgotPasswordModel } from '../models/response/forgot-password';
import { CustomAuthResponseModel, InceptionCustomAuthResponseModel } from '../models/response/custom-auth';
import { DocuwareRecordsModel } from '../models/response/docuware-fields';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Constants } from '../shared/constants';
import { environment } from 'src/environments/environment';
import { IDatePickerConfig } from 'ng2-date-picker';
import * as $ from 'jquery';
import { DocuwareCabinetService } from '../services/docuware-cabinet.service';
import { DocumentTypeListResponseModel, DocumentTypeResponseModel } from '../models/response/document-type-response';
import { CompanyResponseModel } from '../models/response/company-response';
import { enumDatabaseType, enumFieldTypes } from '../shared/enum';
import { DocumentCabinetService } from '../services/document-cabinet.service';
import { DocumentDisplayModel, DocumentTypeDocumentModelForSearch, DocumentTypeDocumentResponseModel, FilterValueModel, LabelValueModel, SearchInputModel, SPFullTextModel } from '../models/response/document-type-document-response';
import { SortEvent } from 'primeng/api';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import { SearchableFieldResponseModel } from '../models/response/searchable-field-response';
import { SearchableFieldValueResponseModel } from '../models/response/searchable-field-value-response';
import { MetadataSearchModel, MetadataSearchRequestModel } from '../models/request/metadata-search-request';
import { DocumentTypeDocumentSearchRequestModel } from '../models/request/document-type-document-request';
import { DomSanitizer } from '@angular/platform-browser';
declare var Tiff: any;

@Component({
  selector: 'app-inception-custom-web-page',
  templateUrl: './inception-custom-web-page.component.html',
  styleUrls: ['./inception-custom-web-page.component.sass'],
  styles: [`
      :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
          position: inherit;
          top: 0;
      }

      .layout-news-active :host ::ng-deep .p-datatable tr > th {
          top: 0;
      }
  `]
})
export class InceptionCustomWebPageComponent extends BaseComponent implements OnInit, OnDestroy {


  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  queryString: string = '';
  cardImageBase64: string = '';
  documentViewerLink: string = '';

  cabinetRequestModel: DocuwareCabinetReuestModel;
  customAuthResponseModel: InceptionCustomAuthResponseModel;
  docuwareRecordsModel: DocuwareRecordsModel[];
  docuwareSearchControlModel: DocuwareRecordsModel[];
  docuwareRecordsBackup: DocuwareRecordsModel[];
  // municipalityResponseModel: MunicipalityResponseModel;
  documentTypeResponseModel: DocumentTypeResponseModel;
  companyResponseModel: CompanyResponseModel;
  // documents: DocumentTypeDocumentResponseModel[];
  documents: DocumentDisplayModel[];

  headers: any[];
  records: any[];
  backupRecords: any[];
  searchRecords: Array<any>;
  searchRecordsTemp: any[];
  backupSearchRecords: any[];
  searchFilterData: any;
  searchFilterDataTemp: Array<any>;
  // isSearch: boolean = false;
  displayClearButton: boolean = false;

  viewerLoader: boolean = false;
  customLoader: boolean = false;
  totalRecordCount: number = 0;
  pageCount: number = 1;
  pageSize: number = 1000;
  pageArray: Array<number>;
  paginationPage: number;
  startRecord: number;
  endRecord: number;
  displayPagination: boolean = true;
  enumDatabaseType: enumDatabaseType;
  isInitialEmptyState: boolean = true;

  selectedValue: any;
  filterResposniveEvent: any;

  // searchInputList: { DisplayAs: string, FieldName: string, FieldType: string, Sequence: number, Values: {label: string , value: string}[] }[];
  // searchInputList: { DisplayAs: string, FieldName: string, FieldType: string, Sequence: number, Values: { label: string, value: string }[], FilteredValues: { label: string, value: string }[] }[];
  searchInputList: SearchInputModel[];
  enumFieldType: enumFieldTypes;
  FilterValues : LabelValueModel[];

  forgotPasswordRequestModel: ForgotPasswordModel;

  isMore: boolean = false;

  displayMessage: boolean = false;
  message: string = '';
  messageClass: boolean;
  config: IDatePickerConfig = {
    format: 'MM/DD/YYYY'
  };

  searchLoader: boolean = true;
  isDocuware: boolean = false;
  showSearchPanel: boolean = false;
  searchableFieldModel: SearchableFieldResponseModel[];
  isSearchHasData: boolean;
  forSepcialCompanyDb: boolean = false;

  filePath: string;
  fileName: string = '';
  fileExtension: string = '';
  DocumentViewerPopup: boolean;
  documentTypeList: DocumentTypeListResponseModel[];
  selectedDocumentType: DocumentTypeListResponseModel;
  selectedFileId: number; 
  listOfFullTextSearchedDocIds: SPFullTextModel[];
  fullTextSearch: string = '';
  metadataSearchModel: MetadataSearchModel[];
  FilterValuesForSpecialCompanies: SearchableFieldValueResponseModel[];
  isImage: boolean;
  isPdf: boolean;
  base64String: string;
  optionList: FilterValueModel[] = [];
  yearRange = '';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private docuwareCabinetService: DocuwareCabinetService, private sanitizer:DomSanitizer,
    private formBuilder: FormBuilder, private documentCabinetService: DocumentCabinetService,
    private cd: ChangeDetectorRef) {
    super();

    // this is for routerLink on same component when only queryParameter changes 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false; 
  }

  filter() {
    this.isInitialEmptyState = false;
    this.loading = true;
    this.customLoader = true;
    var that = this;


    this.cabinetRequestModel.DocuwareFieldData = new Array<DocuwareFieldData>();
    var docuFieldData = new DocuwareFieldData();

    // var ifFullText = this.searchInputList.find(searchbox => searchbox.DisplayAs === 'Full Text');

    // var fieldValue = that.form.get(ifFullText['FieldName']).value;

    if (this.forSepcialCompanyDb) {
      this.searchableFieldModel.forEach(searchBox => {
        docuFieldData = new DocuwareFieldData();

        var searchValue = this.form.get(searchBox.FieldName)?.value;
        if (searchValue) {
          if (searchBox.FieldType === 'Date') {
            const momentDateValue = moment(searchValue, 'MM/DD/YYYY');
            var dateValue = momentDateValue.format('MM/DD/YYYY');
            docuFieldData.FieldName = searchBox.FieldName;
            docuFieldData.FieldValue = dateValue;
          }
          else if (searchBox.FieldType !== 'Date' && searchBox.DisplayAs == 'Full Text') {
            docuFieldData.FieldName = searchBox.FieldName;
            docuFieldData.FieldValue = searchValue;
          }
          else {
            docuFieldData.FieldName = searchBox.FieldName;
            docuFieldData.FieldValue = searchValue.FieldValue;
          }

          this.cabinetRequestModel.DocuwareFieldData.push(docuFieldData);
        }
      });
    }
    else {
      this.searchInputList.forEach(searchBox => {
        docuFieldData = new DocuwareFieldData();
       
        var searchValue = this.form.get(searchBox.FieldName)?.value;
        if (searchValue) {

          if (searchBox.FieldType == 'Date') {
            const momentDateValue = moment(searchValue, 'MM/DD/YYYY');
            var dateValue = momentDateValue.format('MM/DD/YYYY');
            docuFieldData.FieldName = searchBox.FieldName;
            docuFieldData.FieldValue = dateValue;
          }
          else if (searchBox.FieldType !== 'Date' && searchBox.DisplayAs == 'Full Text') {
            docuFieldData.FieldName = searchBox.FieldName;
            docuFieldData.FieldValue = searchValue;
          }
          else {

            // that.searchRecords = that.searchRecords.filter(item => new Date(item[searchBox.DisplayAs]).getDate() == new Date(searchValue).getDate() );
            docuFieldData.FieldName = searchBox.FieldName;
            // docuFieldData.FieldValue = searchValue;
            docuFieldData.FieldValue = searchValue.label;
            docuFieldData.FieldValue = (searchValue.label !== undefined) ? searchValue.label : searchValue;
          }

          this.cabinetRequestModel.DocuwareFieldData.push(docuFieldData);
        }
      });
    }

    if (this.cabinetRequestModel.ServerURL === 'https://nashuanh.docuware.cloud') {
      docuFieldData = new DocuwareFieldData();
      docuFieldData.FieldName = 'PUBLIC_STATUS';
      docuFieldData.FieldValue = 'Public';

      this.cabinetRequestModel.DocuwareFieldData.push(docuFieldData);
    }

    // if(fieldValue) {
    //   this.cabinetRequestModel.SearchFullText = fieldValue.toString();
    // } else {
    //   this.cabinetRequestModel.SearchFullText = '';
    // }
    this.docuwareCabinetService.fullTextSearch(this.cabinetRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<DocuwareRecordsModel[]>) => {
        this.processResult<any>(result, () => {
          if (result.IsSuccess) {
            if (result.ResponseModel) {
              this.docuwareRecordsModel = result.ResponseModel;

              that.records = [];
              that.searchRecords = [];

              this.docuwareRecordsModel.forEach(docuwareRecord => {
                const record = {};

                record["DocumentId"] = docuwareRecord.DocuwareDocumentId;
                docuwareRecord.DocuwareFields.forEach(field => {
                  record[field.DisplayAs] = field.FieldValue;

                });

                that.records.push(record);
              });

              that.searchRecords = that.records;

              for (const prop in this.searchFilterData) {
                if (prop === 'Full Text') {
                  delete this.searchFilterData[prop];
                }
              }

              // if (this.cabinetRequestModel.ServerURL === 'https://nashuanh.docuware.cloud') 
              // {
              this.headers = [];

              this.headers.push({ headerName: "DocumentId", fieldType: 'Numeric', sequence: -1 });
              this.headers.push({ headerName: 'View', fieldType: 'Text', sequence: 0 });
              this.docuwareRecordsModel[0]?.DocuwareFields.forEach(field => {
                if (field.IsDisplay) {

                  this.headers.push({ headerName: field.DisplayAs, fieldType: field.FieldType, sequence: field.DisplaySequence });
                }
              });

              this.headers = this.headers.sort((a, b) => a.sequence - b.sequence);

              this.displayPagination = true;

              this.startRecord = ((this.cabinetRequestModel.PageNo - 1) * (this.pageSize)) + 1;
              // this.endRecord = this.startRecord + this.pageSize -1;
              this.endRecord = this.startRecord + this.searchRecords.length - 1;

              if (this.pageCount == this.paginationPage) {
                this.endRecord = this.totalRecordCount;
              }
              // }

            }
            this.displayPagination = false;
            this.loading = false;
            this.customLoader = false;

          }
          else {
            this.loading = false;
            this.customLoader = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.customLoader = false;
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  filterInAppDocs() {

    this.isInitialEmptyState = false;
    this.loading = true;
    this.customLoader = true;

    if(this.fullTextSearch !== '' && this.fullTextSearch !== null && this.fullTextSearch !== undefined) {
      const reqModel = new DocumentTypeDocumentSearchRequestModel();
      reqModel.DocumentTypeId = this.documentTypeResponseModel?.DocumentTypeId;
      reqModel.SearchText = this.fullTextSearch;

      // this.documentCabinetService.GetDocumentTypeDocumentIdsForFullText(this.documentTypeResponseModel?.DocumentTypeId, this.fullTextSearch)
      this.documentCabinetService.GetDocumentTypeDocumentIdsForFullText(reqModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<SPFullTextModel[]>) => {
        this.processResult<SPFullTextModel[]>(result, () => {
          if (result.IsSuccess) {
            
            if (result.ResponseModel) {
              this.listOfFullTextSearchedDocIds = new Array<SPFullTextModel>();
              if(result.ResponseModel.length > 0) {
                this.listOfFullTextSearchedDocIds = result.ResponseModel;

                var fullTextSearchedRecords = new Array<any>();
                // filter the records that are in list of full text search ids list

                this.listOfFullTextSearchedDocIds.forEach(docId => {
                  // var selectedRecord = this.backupRecords.find(x => x.documentTypeDocumentId === docId.DocumentTypeDocumentId);
                  const selectedRecord = this.backupRecords.filter((obj) => {
          
                    return obj['DocumentTypeInboxId']?.toString().toLowerCase() === docId.DocumentTypeInboxId.toString().toLowerCase();
                  });
                  
                  // if (selectedRecord !== undefined || selectedRecord !== null || selectedRecord.length > 0) {
                  if (selectedRecord !== undefined && selectedRecord !== null && selectedRecord.length > 0) {
                    fullTextSearchedRecords.push(selectedRecord[0]);
                  }
                });

                this.searchRecords = fullTextSearchedRecords;

                if (this.metadataSearchModel.length > 0) {
                  // this.searchRecords = new Array<any>();
                  this.searchRecordsTemp = fullTextSearchedRecords;
                  this.searchInMetadata();
                }
                else {
                  this.searchRecords = fullTextSearchedRecords;
                }
              }
              else {
                this.searchRecords = new Array<any>();
              }

            }
          }
          else {
            this.loading = false;

            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;

        this.timeoutFunct(httpResponseError.message, false);
      });

    } else {
        //this.searchRecords = this.backupRecords;
        this.searchInMetadata();
      }

    // this.searchRecords = this.backupRecords;
    
    // var that = this;
    // this.searchFilterDataTemp.forEach(value => {
    //   
    //   if (value.FieldType === enumFieldTypes.enumInteger) {
    //     const result = that.searchRecords.filter((obj) => {
          
    //       return parseInt(obj[value.FieldName]) >= parseInt(value.FieldValueFrom) && parseInt(obj[value.FieldName]) <= parseInt(value.FieldValueTo);
    //     });
    //     that.searchRecords = result;
    //   }
    //   else if (value.FieldType === enumFieldTypes.enumDecimal || value.FieldType === enumFieldTypes.enumCurrency) {
    //     
    //      const result = that.searchRecords.filter((obj) => {
    //        return parseFloat(obj[value.FieldName]) >= parseFloat(value.FieldValueFrom) && parseFloat(obj[value.FieldName]) <= parseFloat(value.FieldValueTo);
    //      });
    //      that.searchRecords = result;
    //   }
    //   else if (value.FieldType === enumFieldTypes.enumDate) {
    //     
    //     const result = that.searchRecords.filter((obj) => {
    //       // return (obj[value.FieldName] as Date) >= (value.FieldValueFrom as Date) && (obj[value.FieldName] as Date) <= (value.FieldValueTo as Date);
    //       return (moment(obj[value.FieldName], 'MM/DD/YYYY').format('YYYY/MM/DD')) >= (moment(value.FieldValueFrom, 'MM/DD/YYYY').format('YYYY/MM/DD')) && (moment(obj[value.FieldName], 'MM/DD/YYYY').format('YYYY/MM/DD')) <= (moment(value.FieldValueTo, 'MM/DD/YYYY').format('YYYY/MM/DD'));
    //     });
    //     that.searchRecords = result;
    //   }
    //   else {
    //     const result = that.searchRecords.filter((obj) => {
    //       
    //       return obj[value.FieldName]?.toString().toLowerCase() === value.FieldValueFrom.toString().toLowerCase();
    //     });
    //     that.searchRecords = result;
    //   }



    // });
    
    //   //that.searchRecords = that.searchRecords.filter(item => {


    //   //let notMatchingField = Object.keys(this.searchFilterData).find(key =>

    //   //  !item[key]?.toString().toLowerCase().includes(this.searchFilterData[key]?.toString().toLowerCase())

    //   //);

    //   //return !notMatchingField; // true if matches all fields

    // //});

    this.loading = false;
    this.customLoader = false;
    this.searchLoader = false;
  }

  searchInMetadataOLD(){
    
    var that = this;
    this.searchFilterDataTemp.forEach(value => {

      if (value.FieldType === enumFieldTypes.enumInteger) {

        const result = that.searchRecords.filter((obj) => {          
          return parseInt(obj[value.FieldName]) >= parseInt(value.FieldValueFrom) && parseInt(obj[value.FieldName]) <= parseInt(value.FieldValueTo);
        });
        that.searchRecords = result;
      }
      else if (value.FieldType === enumFieldTypes.enumDecimal || value.FieldType === enumFieldTypes.enumCurrency) {

         const result = that.searchRecords.filter((obj) => {
           return parseFloat(obj[value.FieldName]) >= parseFloat(value.FieldValueFrom) && parseFloat(obj[value.FieldName]) <= parseFloat(value.FieldValueTo);
         });
         that.searchRecords = result;
      }
      else if (value.FieldType === enumFieldTypes.enumDate) {

        const result = that.searchRecords.filter((obj) => {
          // return (obj[value.FieldName] as Date) >= (value.FieldValueFrom as Date) && (obj[value.FieldName] as Date) <= (value.FieldValueTo as Date);
          return (moment(obj[value.FieldName], 'MM/DD/YYYY').format('YYYY/MM/DD')) >= (moment(value.FieldValueFrom, 'MM/DD/YYYY').format('YYYY/MM/DD')) && (moment(obj[value.FieldName], 'MM/DD/YYYY').format('YYYY/MM/DD')) <= (moment(value.FieldValueTo, 'MM/DD/YYYY').format('YYYY/MM/DD'));
        });
        that.searchRecords = result;
      }
      else {
        const result = that.searchRecords.filter((obj) => {
          return obj[value.FieldName]?.toString().toLowerCase() === value.FieldValueFrom.toString().toLowerCase();
        });
        that.searchRecords = result;
      }

    });
  }

  searchInMetadata(){
    
    var reqModel = new MetadataSearchRequestModel();
    reqModel.DocumentTypeId = this.documentTypeResponseModel.DocumentTypeId;
    reqModel.forCustomPage = true;
    reqModel.MetadataSearch = this.metadataSearchModel;

    this.documentCabinetService.GetMetadataSearchData(reqModel)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
    .subscribe((result: ResultOfT<DocumentTypeDocumentModelForSearch>) => {
      this.processResult<DocumentTypeDocumentModelForSearch>(result, () => {
        if (result.IsSuccess) {
          if (result.ResponseModel) {
            
            if(result.ResponseModel.BackupSearchRecords?.length > 0) {
              // this.searchRecords = result.ResponseModel.BackupSearchRecords;

              if (!(this.listOfFullTextSearchedDocIds?.length > 0 && this.searchRecordsTemp?.length > 0)) {
                
                // this.searchRecords = this.backupSearchRecords;                
                this.searchRecordsTemp = this.backupSearchRecords;
              }
              //var filteredRecords = this.searchRecords.filter(function (o1) {
              //  // filter out (!) items in result2
              //  return result.ResponseModel.BackupSearchRecords.some(function (o2) {
              //    return o1['DocumentTypeDocumentId'] === o2['DocumentTypeDocumentId'];          // assumes unique id
              //  });
              //});
              var filteredRecords = this.searchRecordsTemp.filter(function (o1) {
                // filter out (!) items in result2
                return result.ResponseModel.BackupSearchRecords.some(function (o2) {
                  // return o1['DocumentTypeDocumentId'] === o2['DocumentTypeDocumentId'];          // assumes unique id
                  return o1['DocumentTypeInboxId'] === o2['DocumentTypeInboxId'];          // assumes unique id
                });
              });
              
              if (filteredRecords.length > 0) {
                this.searchRecords = filteredRecords;
              }
            }
            else {
              this.searchRecords = new Array<any>();
            }

          }
        }
        else {
          this.loading = false;

          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.loading = false;

      this.timeoutFunct(httpResponseError.message, false);
    });
    
  }

  filterClickEvent() {
    this.filterResposniveEvent = !this.filterResposniveEvent;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngOnInit(): void {
    this.yearRange = '1900:' + (new Date().getFullYear() + 50);
    this.searchFilterData = {};
    this.searchFilterDataTemp = new Array<any>();
    this.forgotPasswordRequestModel = new ForgotPasswordModel();
    this.documentTypeResponseModel = new DocumentTypeResponseModel();
    this.metadataSearchModel = new Array<MetadataSearchModel>();

    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {

        this.queryString = params['queryString'];
      });

    if (this.queryString) {
      if(this.queryString.charAt(this.queryString.length - 1) !== '='){
        this.forgotPasswordRequestModel.encryptedString = this.queryString + "=";
      } else {
        this.forgotPasswordRequestModel.encryptedString = this.queryString;
      }
      this.authenticateCustomPage();
    }


    this.paginationPage = parseInt($('.cdp').attr('actpage'), 10);

  }

  onPageClick(pagenumber: number) {
    this.cabinetRequestModel.PageNo = pagenumber;

    this.getFileCabinetRecordsById();

  }

  // onSelectChange(searchBox: any) {
  //   
  //   this.searchInputList;
  //   var searchValue = this.form.get(searchBox.FieldName)?.value;
  //   if (searchValue) {
  //     this.isDocuware ? this.searchFilterData[searchBox.DisplayAs] = searchValue : this.searchFilterDataTemp[searchBox.DisplayAs] = searchValue;
  //   } else {
  //     this.isDocuware ? delete this.searchFilterData[searchBox.DisplayAs] : delete this.searchFilterDataTemp[searchBox.DisplayAs];
  //   }

  // }

  onSelectChange(event :any,searchBox: any) {

    if(event?.label) {

      this.form.get(searchBox.FieldName).setValue(event);
      var searchValue = this.form.get(searchBox.FieldName)?.value;
      if (searchValue) {
        if(this.searchFilterDataTemp == undefined){
          this.searchFilterDataTemp = new Array<any>();
        }
        this.isDocuware ? this.searchFilterData[searchBox.DisplayAs] = searchValue : this.searchFilterDataTemp[searchBox.DisplayAs] = searchValue;
      } else {
        //delete this.searchFilterDataTemp[searchBox.DisplayAs];
      }
    }
    else {
      if(event?.FieldValue) {
        this.form.get(searchBox.FieldName).setValue(event);
        var searchValue1 = this.form.get(searchBox.FieldName)?.value;
      if (searchValue1) {
        if(this.searchFilterDataTemp == undefined){
          this.searchFilterDataTemp = new Array<any>();
        }
        this.isDocuware ? this.searchFilterData[searchBox.DisplayAs] = searchValue1 : this.searchFilterDataTemp[searchBox.DisplayAs] = searchValue1;
      } else {
        //delete this.searchFilterDataTemp[searchBox.DisplayAs];
      }
      } else {
        if(searchBox?.DocumentTypeMetadataId == 0) {
          this.form.get(searchBox.FieldName).setValue(event.value);
        } 
        else {
          if(this.isDocuware) {
            this.form.get(searchBox.FieldName).setValue(null);
            if(this.searchFilterDataTemp?.length > 0) {
              this.isDocuware ? delete this.searchFilterData[searchBox.DisplayAs] : delete this.searchFilterDataTemp[searchBox.DisplayAs];
            }
            else {
              this.form.get(searchBox.FieldName).setValue(event?.value);
              this.searchFilterData[searchBox.DisplayAs] = this.form.get(searchBox.FieldName)?.value;
            }
          }
        }
      }
    }
  }

  onDropdownChange(event :any,searchBox: any) {
    
        if(event?.value) {
    
          this.form.get(searchBox.FieldName).setValue(event.value);
          var searchValue = this.form.get(searchBox.FieldName)?.value;
          if (searchValue) {
            if(this.searchFilterDataTemp == undefined){
              this.searchFilterDataTemp = new Array<any>();
            }
            this.isDocuware ? this.searchFilterData[searchBox.DisplayAs] = searchValue : this.searchFilterDataTemp[searchBox.DisplayAs] = searchValue;
          } else {
            //delete this.searchFilterDataTemp[searchBox.DisplayAs];
          }
        }
        else {
          if(event?.FieldValue) {
            this.form.get(searchBox.FieldName).setValue(event);
            var searchValue1 = this.form.get(searchBox.FieldName)?.value;
          if (searchValue1) {
            if(this.searchFilterDataTemp == undefined){
              this.searchFilterDataTemp = new Array<any>();
            }
            this.isDocuware ? this.searchFilterData[searchBox.DisplayAs] = searchValue1 : this.searchFilterDataTemp[searchBox.DisplayAs] = searchValue1;
          } else {
            //delete this.searchFilterDataTemp[searchBox.DisplayAs];
          }
          } else {
            if(searchBox?.DocumentTypeMetadataId == 0) {
              this.form.get(searchBox.FieldName).setValue(event.value);
            } 
            else {
              if(this.isDocuware) {
                this.form.get(searchBox.FieldName).setValue(null);
                if(this.searchFilterDataTemp?.length > 0) {
                  this.isDocuware ? delete this.searchFilterData[searchBox.DisplayAs] : delete this.searchFilterDataTemp[searchBox.DisplayAs];
                }
                else {
                  this.form.get(searchBox.FieldName).setValue(event?.value);
                  this.searchFilterData[searchBox.DisplayAs] = this.form.get(searchBox.FieldName)?.value;
                }
              }
            }
          }
        }
      }
 
  //// This was used when we didn't apply API call for filter
  // filterAutoCompleteSuggestion(event: any, searchBox) {

  //   searchBox.FilteredValues = new Array<{ label: '', value: '' }>();

  //   let query = event.query;
  //   if (query.length > 0) {
  //     searchBox.FilteredValues = searchBox.Values.filter(x => x.label.toLowerCase().trim().includes(query.toLowerCase().trim()));
  //   }
  // }

  filterAutoCompleteSuggestion(event: any, searchBox) {
    
    let query = event.query;
    
    if(query.length >= 2) {
      searchBox.FilteredValues = new Array<any>();

      this.documentCabinetService.GetFilterDataForSearch(searchBox.DocumentTypeMetadataId, query)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<LabelValueModel[]>) => {
        this.processResult<LabelValueModel[]>(result, () => {
          this.loading = true;
          if (result.IsSuccess) {
            searchBox.FilteredValues = result.ResponseModel;
            this.FilterValues = result.ResponseModel;

            this.loading = false;
          } else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });

    }
    else {
      searchBox.FilteredValues = new Array<LabelValueModel>();
    }
  }

  filterDropdownSuggestion(searchBox: number, fieldName: string) {
    
    // searchBox.FilteredValues = new Array<any>();
    
      this.documentCabinetService.GetFilterData(searchBox)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<LabelValueModel[]>) => {
        
        this.processResult<LabelValueModel[]>(result, () => {
          console.log(result.ResponseModel);
          this.loading = true;
          
          if (result.IsSuccess) {
           
            result.ResponseModel.forEach(
              x=> {
                this.optionList.push({FieldName: fieldName, label: x.label, value: x.value})
              }
            )
           
            this.loading = false;
          } else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });

  }

  BindDropdown(fieldName: string){
    
    var lst: FilterValueModel[] = [];
    lst.push({ FieldName: fieldName, label: "All", value: "" });
    this.optionList.filter(x => x.FieldName == fieldName).forEach(x => {
      lst.push(x);
    });
    return lst;
  }

  filterAutoCompleteSuggestionForSpecialCompanies(event: any, searchBox) {
    

    
    let query = event.query;
    if (query.length > 0) {
      // searchBox.SearchFieldValuesFiltered = searchBox.SearchableFieldValues.filter(x => x.FieldValue.toString().toLowerCase().trim().includes(query.toLowerCase().trim()));
      // searchBox.SearchFieldValuesFiltered = searchBox.SearchFieldValuesFiltered.filter((thing, i, arr) => {
        //   return arr.indexOf(arr.find(t => t.FieldValue.toString().toLowerCase().trim() === thing.FieldValue.toString().toLowerCase().trim())) === i;
        // });
        searchBox.SearchFieldValuesFiltered = new Array<SearchableFieldValueResponseModel>();
        this.documentCabinetService.GetFilterDataForSpecialCompanies(searchBox.SearchableFieldId, query)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<SearchableFieldValueResponseModel[]>) => {
          this.processResult<SearchableFieldValueResponseModel[]>(result, () => {
            this.loading = true;
            if (result.IsSuccess) {
              searchBox.FilteredValues = result.ResponseModel;
              this.FilterValuesForSpecialCompanies = result.ResponseModel;
  
              this.loading = false;
            } else {
              this.loading = false;
              this.timeoutFunct(result.Message, false);
            }
          });
        }, (httpResponseError) => {
          this.loading = false;
          this.timeoutFunct(httpResponseError.message, false);
        });
    }
    else {
      searchBox.SearchFieldValuesFiltered = new Array<SearchableFieldValueResponseModel>();
    }
  }

  onSearchOLD() {
    // this.isSearch = true;
    this.searchFilterDataTemp = new Array<any>();
    this.displayClearButton = true;
    if (this.isDocuware) {
      var searchValue = this.form.get("FULL_TEXT")?.value;
      if (searchValue) {
        this.searchFilterData['Full Text'] = searchValue;
      }
      const countKeys = Object.keys(this.searchFilterData);
      if (countKeys.length > 0) {

        // this.searchRecords = this.backupRecords;
        this.filter();
      } else {
        this.displayClearButton = false;
        this.timeoutFunct('Please select search filter', false);
      }
    }
    else {
      for(let searchBox of this.searchInputList) {
        if(!(searchBox.FieldName === 'FULL_TEXT')) {

          var searchDataItem = {};
          if (searchBox.FieldType === enumFieldTypes.enumInteger || searchBox.FieldType === enumFieldTypes.enumDecimal || searchBox.FieldType === enumFieldTypes.enumCurrency) {
            var searchValueFrom = this.form.get(searchBox.FieldName + 'From')?.value;
            var searchValueTo = this.form.get(searchBox.FieldName + 'To')?.value;
  
            //  this.searchFilterDataTemp[searchBox.DisplayAs+'From'] = searchValueFrom;
            //this.searchFilterDataTemp[searchBox.DisplayAs + 'To'] = searchValueTo;
            if (!this.searchFilterDataTemp.includes(searchBox.FieldName)) {
              if (searchValueFrom && searchValueTo) {
                searchDataItem['FieldName'] = searchBox.FieldName;
                searchDataItem['FieldType'] = searchBox.FieldType;
                searchDataItem['FieldValueFrom'] = searchValueFrom;
                searchDataItem['FieldValueTo'] = searchValueTo;
                searchDataItem['DocumentTypeMetadataId'] = searchBox.DocumentTypeMetadataId;
  
                if(parseFloat(searchValueFrom) > parseFloat(searchValueTo)) {
                  this.timeoutFunct('Please apply appropriate '+ searchBox.FieldName +' range', false);
                  return;
                }
  
                this.searchFilterDataTemp.push(searchDataItem);
              }
              else if(searchValueFrom && !searchValueTo) {
                this.timeoutFunct('Please apply ' + searchBox.FieldName + ' To filter', false);
                return;
              } 
              else if(!searchValueFrom && searchValueTo) {
                this.timeoutFunct('Please apply ' + searchBox.FieldName + ' From filter', false);
                return;
              }
            }
  
          } 
          else if (searchBox.FieldType === enumFieldTypes.enumDate) {
            var searchValueFrom = this.form.get(searchBox.FieldName + 'From')?.value;
            var searchValueTo = this.form.get(searchBox.FieldName + 'To')?.value;
  
            if (!this.searchFilterDataTemp.includes(searchBox.FieldName)) {
              if (searchValueFrom && searchValueTo) {
                searchDataItem['FieldName'] = searchBox.FieldName;
                searchDataItem['FieldType'] = searchBox.FieldType;
                searchDataItem['DocumentTypeMetadataId'] = searchBox.DocumentTypeMetadataId;
  
                const momentDateValueFrom = moment(searchValueFrom, 'MM/DD/YYYY');
                var dateValueFrom = momentDateValueFrom.format('MM/DD/YYYY');
                searchDataItem['FieldValueFrom'] = dateValueFrom;
                const momentDateValueTo = moment(searchValueTo, 'MM/DD/YYYY');
                var dateValueTo = momentDateValueTo.format('MM/DD/YYYY');
                searchDataItem['FieldValueTo'] = dateValueTo;
  
                if(dateValueFrom > dateValueTo) {
                  this.timeoutFunct('Please apply appropriate date range', false);
                  return;
                }
  
                this.searchFilterDataTemp.push(searchDataItem);
              }
              else if (searchValueFrom && !searchValueTo) {
                this.timeoutFunct('Please apply ' + searchBox.FieldName + ' To filter', false);
                return;
              }
              else if (!searchValueFrom && searchValueTo) {
                this.timeoutFunct('Please apply ' + searchBox.FieldName + ' From filter', false);
                return;
              }
            }
          }
          else {
            var searchValue = this.form.get(searchBox.FieldName)?.value?.label ? this.form.get(searchBox.FieldName)?.value?.label : this.form.get(searchBox.FieldName)?.value;

            if (!this.searchFilterDataTemp.includes(searchBox.FieldName)) {
              if (searchValue) {
                searchDataItem['FieldName'] = searchBox.FieldName;
                searchDataItem['FieldType'] = searchBox.FieldType;
                searchDataItem['FieldValueFrom'] = searchValue;
                searchDataItem['FieldValueTo'] = searchValue;
                searchDataItem['DocumentTypeMetadataId'] = searchBox.DocumentTypeMetadataId;
                this.searchFilterDataTemp.push(searchDataItem);
              }
            }
          }
        }
        else {
          this.fullTextSearch = this.form.get('FULL_TEXT')?.value;
        }
        

        // else 
        // {
        //   var searchValue = this.form.get(searchBox.FieldName)?.value;
        //   if (searchValue) {
        //     if (searchBox.FieldType === 'Date') {
  
        //       const momentDateValue = moment(searchValue, 'MM/DD/YYYY');
        //       var dateValue = momentDateValue.format('MM/DD/YYYY');
        //       this.searchFilterData[searchBox.DisplayAs] = dateValue;
  
        //     }
        //     else if (searchBox.FieldType !== 'Date' && searchBox.DisplayAs == 'Full Text') {
        //       this.searchFilterData[searchBox.DisplayAs] = searchValue;
        //     }
        //     else {
        //       if (searchValue.label)
        //         this.searchFilterData[searchBox.DisplayAs] = searchValue.label;
        //       else
        //         this.searchFilterData[searchBox.DisplayAs] = searchValue;
        //     }
        //   }
        // }

      }
      if (this.searchFilterDataTemp.length > 0 || this.fullTextSearch !== '') {
        this.filterInAppDocs();
      } else {
        this.displayClearButton = false;
        this.timeoutFunct('Please select search filter', false);
      }

    }

  }

  onSearch() {

    // this.isSearch = true;
    // this.searchFilterDataTemp = new Array<any>();
    
    this.searchRecords = [];
    this.searchRecordsTemp = [];

    this.displayClearButton = true;
    if (this.isDocuware) {
      var searchValue = this.form.get("FULL_TEXT")?.value;
      if (searchValue) {
        this.searchFilterData['Full Text'] = searchValue;
      }
      const countKeys = Object.keys(this.searchFilterData);
      if (countKeys.length > 0) {

        // this.searchRecords = this.backupRecords;
        this.filter();
      } else {
        this.displayClearButton = false;
        this.timeoutFunct('Please select search filter', false);
      }
    }
    else {

      this.metadataSearchModel = new Array< MetadataSearchModel>();
      for(let searchBox of this.searchInputList) {

        if(!(searchBox.FieldName === 'FULL_TEXT')) {

          var searchDataItem = new MetadataSearchModel;
          if (searchBox.FieldType === enumFieldTypes.enumInteger || searchBox.FieldType === enumFieldTypes.enumDecimal || searchBox.FieldType === enumFieldTypes.enumCurrency) {
            var searchValueFrom = this.form.get(searchBox.FieldName + 'From')?.value;
            var searchValueTo = this.form.get(searchBox.FieldName + 'To')?.value;
  
            //  this.searchFilterDataTemp[searchBox.DisplayAs+'From'] = searchValueFrom;
            //this.searchFilterDataTemp[searchBox.DisplayAs + 'To'] = searchValueTo;
            if (!this.searchFilterDataTemp.includes(searchBox.FieldName)) {
              if (searchValueFrom && searchValueTo) {
                searchDataItem.FieldName = searchBox.FieldName;
                searchDataItem.FieldType = searchBox.FieldType;
                searchDataItem.FieldValueFrom = searchValueFrom;
                searchDataItem.FieldValueTo = searchValueTo;
                searchDataItem.DocumentTypeMetadataId = searchBox.DocumentTypeMetadataId;
  
                if(parseFloat(searchValueFrom) > parseFloat(searchValueTo)) {
                  this.timeoutFunct('Please apply appropriate '+ searchBox.FieldName +' range', false);
                  return;
                }
  
                this.metadataSearchModel.push(searchDataItem);
              }
              else if(searchValueFrom && !searchValueTo) {
                this.timeoutFunct('Please apply ' + searchBox.FieldName + ' To filter', false);
                return;
              } 
              else if(!searchValueFrom && searchValueTo) {
                this.timeoutFunct('Please apply ' + searchBox.FieldName + ' From filter', false);
                return;
              }
            }
  
          } 
          else if (searchBox.FieldType === enumFieldTypes.enumDate) {
            
            var searchValueFrom = this.form.get(searchBox.FieldName + 'From')?.value;
            var searchValueTo = this.form.get(searchBox.FieldName + 'To')?.value;
  
            if (!this.searchFilterDataTemp.includes(searchBox.FieldName)) {
              if (searchValueFrom && searchValueTo) {
                searchDataItem.FieldName = searchBox.FieldName;
                searchDataItem.FieldType = searchBox.FieldType;
                searchDataItem.DocumentTypeMetadataId = searchBox.DocumentTypeMetadataId;
  
                const momentDateValueFrom = moment(searchValueFrom, 'MM/DD/YYYY');
                var dateValueFrom = momentDateValueFrom.format('MM/DD/YYYY');
                searchDataItem.FieldValueFrom = dateValueFrom;
                const momentDateValueTo = moment(searchValueTo, 'MM/DD/YYYY');
                var dateValueTo = momentDateValueTo.format('MM/DD/YYYY');
                searchDataItem.FieldValueTo = dateValueTo;
  
                // if(dateValueFrom > dateValueTo) {
                //   this.timeoutFunct('Please apply appropriate date range', false);
                //   return;
                // }
                if(momentDateValueFrom > momentDateValueTo) {
                  this.timeoutFunct('Please apply appropriate date range', false);
                  return;
                }
  
                this.metadataSearchModel.push(searchDataItem);
              }
              else if (searchValueFrom && !searchValueTo) {
                this.timeoutFunct('Please apply ' + searchBox.FieldName + ' To filter', false);
                return;
              }
              else if (!searchValueFrom && searchValueTo) {
                this.timeoutFunct('Please apply ' + searchBox.FieldName + ' From filter', false);
                return;
              }
            }
          }
          else {
            
            var searchValue = this.form.get(searchBox.FieldName)?.value?.label ? this.form.get(searchBox.FieldName)?.value?.label : this.form.get(searchBox.FieldName)?.value;
            
            if (!this.searchFilterDataTemp.includes(searchBox.FieldName)) {
              if (searchValue) {
                searchDataItem.FieldName = searchBox.FieldName;
                searchDataItem.FieldType = searchBox.FieldType;
                searchDataItem.FieldValueFrom = searchValue;
                searchDataItem.FieldValueTo = searchValue;
                searchDataItem.DocumentTypeMetadataId = searchBox.DocumentTypeMetadataId;
                this.metadataSearchModel.push(searchDataItem);
              }
            }
          }
        }
        else {
          this.fullTextSearch = this.form.get('FULL_TEXT')?.value;
        }
        
      }
      if (this.metadataSearchModel.length > 0 || (this.fullTextSearch !== '' && this.fullTextSearch != null && this.fullTextSearch != undefined)) {
        this.filterInAppDocs();
      } else {       
        this.displayClearButton = false;
        this.timeoutFunct('Please select search filter', false);
      }

    }

  }

  recFunct(recordList: DocuwareRecordsModel[], searchFieldName: string, searchValue: string) {
    var searchedRecords = new Array<DocuwareRecordsModel>();

    if (recordList?.length > 0) {

      recordList.forEach(record => {

        var result = record.DocuwareFields.filter(x => x.FieldName == searchFieldName && x.FieldValue.includes(searchValue));
        if (result.length !== 0) {
          searchedRecords.push(record);
        }

      });
    }
    return searchedRecords;
  }


  onReset() {
    this.form.reset();
    this.searchFilterData = {};
    this.displayClearButton = false;
    // this.isSearch = false;

    // this.searchRecords = this.backupRecords;
    // this.displayPagination = true;

    // if (this.cabinetRequestModel.ServerURL !== 'https://nashuanh.docuware.cloud') {
    //   this.searchRecords = this.backupRecords;
    //   this.displayPagination = true;
    // }
    // else {
    //   this.searchRecords = null;
    //   this.displayPagination = false;
    // }

    this.displayPagination = false;
    this.isInitialEmptyState = true;

    if(this.isDocuware) {
      this.searchRecords = null;
      this.searchRecordsTemp = null;
    } else {
      this.searchRecords = this.backupRecords;
      this.searchRecordsTemp = this.backupRecords;
    }

  }

  onDownload(record: any) {


    this.cabinetRequestModel.DocuwareDocumentId = record.DocumentId.toString();

    this.docuwareCabinetService.generateDocumentViewerLink(this.cabinetRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<string>) => {
        this.processResult<any>(result, () => {
          if (result.IsSuccess) {
            if (result.ResponseModel) {

              this.documentViewerLink = result.ResponseModel;
              window.open(this.documentViewerLink, "_blank");

            }
          }
          else {
            this.loading = false;

            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;

        this.timeoutFunct(httpResponseError.message, false);
      });

  }

  onView(id) {
    
    this.base64String = '';
    var foundDoc = this.searchRecords.find((obj) => {
      // return parseInt(obj['DocumentTypeDocumentId']) === id;
      return parseInt(obj['DocumentTypeInboxId']) === id;
    });
    if(foundDoc !== undefined) {
      this.DocumentViewerPopup = true;
      // var documentPath = foundDoc['DocumentPath'];
      // this.fileName = documentPath.split('\\').pop().split('/').pop();
      // var indexOf = documentPath.indexOf('\Documents');
      // var fileNamePath = documentPath.substr(indexOf, foundDoc.DocumentPath.length)
      // this.fileExtension = this.fileName.substr(this.fileName.lastIndexOf('.') + 1, this.fileName.length).toLowerCase();
      const fileName = foundDoc?.DocumentPath.split('\\').pop().split('/').pop();
      this.fileExtension = fileName.split('.').pop().toLowerCase();

      const forFileName = this.documents?.find(x => x.DocumentTypeInboxId === id);
      this.fileName = (forFileName !== undefined) ? forFileName.ActualDocumentName : fileName ;
      
      var baseUrl = environment.baseUrl;
      // this.filePath = baseUrl + fileNamePath;
      this.selectedFileId = id;

      if(foundDoc !== undefined) {
        this.DocumentViewerPopup = true;
      
       
      const reqModel = new DownloadFile();
      reqModel.StrFileName = fileName;
      reqModel.StrCompanyId = this.companyResponseModel?.CompanyId.toString();
      reqModel.StrDocumentTypeId = this.documentTypeResponseModel?.DocumentTypeId.toString();
      // reqModel.DownloadFromInbox = true;
      reqModel.DownloadFromInbox = false;

      this.viewerLoader = true;
      this.documentCabinetService.GetBase64ForViewer(reqModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<string>) => {
        this.processResult<string>(result, () => {
          
          if(result) {
            // this.base64String = '';
            if(result.ResponseModel != '') {
              switch(this.fileExtension.toLowerCase()) {
                case '.png':
                case 'png':
                  this.isImage = true;
                  this.isPdf = false;
                  this.base64String = 'data:image/png;base64,' + result.ResponseModel;                
                  break;
  
                case 'jpg':
                  this.isImage = true;
                  this.isPdf = false;
                  this.base64String = this.base64String = 'data:image/jpeg;base64,' + result.ResponseModel;
                  break;

                case 'tiff':
                  this.isImage = true;
                  this.isPdf = false;
                  const binaryData = Uint8Array.from(atob(result.ResponseModel), c => c.charCodeAt(0)); 
                  const tiff = new Tiff({buffer: binaryData}); 
                  const canvas = tiff.toCanvas();
                  const jpgBase64Data = canvas.toDataURL("image/jpeg").replace(/^data:image\/jpeg;base64,/, "");
                  this.base64String="data:image/jpeg;base64,"+jpgBase64Data;
                  break;
  
                case 'pdf':
                  this.isImage = false;
                  this.isPdf = true;
                  this.base64String = this.base64String = 'data:application/pdf;base64,' + result.ResponseModel;
              }
            }
  
            // importedSaveAs(result,fileName);
          } else {
            this.timeoutFunct(result.Message, false);
          }
        });
        this.viewerLoader = false;
      }, (httpResponseError) => {
        
        this.viewerLoader = false;
        this.timeoutFunct(httpResponseError.message, false);
      });
     
      }
    }

  }
  transformImg(){
    if(this.base64String != '') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.base64String);
    }
  }

  onCloseDocumentViewer (){
    this.DocumentViewerPopup = false;
    this.base64String = '';
    this.isImage = false;
    this.isPdf = false;
    this.viewerLoader= false;
  }

  onFileDownload(id) {
    
    var foundDoc = this.searchRecords.find((obj) => {
      // return parseInt(obj['DocumentTypeDocumentId']) === id;
      return parseInt(obj['DocumentTypeInboxId']) === id;
    });
    if (foundDoc !== undefined) {
      var documentPath = foundDoc['DocumentPath'];
      const fileName = documentPath.split('\\').pop().split('/').pop();

      const reqModel = new DownloadFile();
      reqModel.StrFileName = fileName;
      reqModel.StrCompanyId = this.companyResponseModel?.CompanyId.toString();
      reqModel.StrDocumentTypeId = this.documentTypeResponseModel?.DocumentTypeId.toString();
      reqModel.DownloadFromInbox = false;

      this.documentCabinetService.DownloadFile(reqModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: any) => {
          this.processResult<any>(result, () => {

            if (result) {
              importedSaveAs(result, this.fileName);
            } else {
              this.timeoutFunct(result.Message, false);
            }
          });
        }, (httpResponseError) => {
          this.timeoutFunct(httpResponseError.message, false);
        });
    }
  }

  onPdfDownload(id) {
    
    var foundDoc = this.searchRecords.find((obj) => {
      // return parseInt(obj['DocumentTypeDocumentId']) === id;
      return parseInt(obj['DocumentTypeInboxId']) === id;
    });
    if (foundDoc !== undefined) {
      var documentPath = foundDoc['DocumentPath'];
      const fileName = documentPath.split('\\').pop().split('/').pop();

      const reqModel = new DownloadFile();
      reqModel.StrFileName = fileName;
      reqModel.StrCompanyId = this.companyResponseModel?.CompanyId.toString();
      reqModel.StrDocumentTypeId = this.documentTypeResponseModel?.DocumentTypeId.toString();
      reqModel.DownloadFromInbox = false;

      this.documentCabinetService.DownloadFile(reqModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: any) => {
          this.processResult<any>(result, () => {

            if (result) {
              
              // importedSaveAs(result, this.fileName);
              var file = new Blob([result], {type: 'application/pdf'});
              const blobURL= URL.createObjectURL(file);
              // window.open(blobURL, '_blank');
              var iframe =  document.createElement('iframe'); //load content in an iframe to print later
              document.body.appendChild(iframe);

              iframe.style.display = 'none';
              iframe.src = blobURL;
              // iframe.focus();
              // iframe.contentWindow.print();

              iframe.onload = function() {
                setTimeout(function() {
                  iframe.focus();
                  iframe.contentWindow.print();
                }, 500);
              };

            } else {
              this.timeoutFunct(result.Message, false);
            }
          });
        }, (httpResponseError) => {
          this.timeoutFunct(httpResponseError.message, false);
        });
    }
  }

  customSort(event: SortEvent) {
    // event.data.sort((data1, data2) => {
    //   let value1 = data1[event.field];
    //   let value2 = data2[event.field];
    //   let result = null;

    //   if (value1 == null && value2 != null)
    //     result = -1;
    //   else if (value1 != null && value2 == null)
    //     result = 1;
    //   else if (value1 == null && value2 == null)
    //     result = 0;
    //   else if (typeof value1 === 'string' && typeof value2 === 'string')
    //     result = value1.localeCompare(value2);
    //   else
    //     result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //   return (event.order * result);
    // });


    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (value1 != null &&  value2 != null ) {
        if( event.field.toLocaleLowerCase().includes('date')) {
          var datevalue1 = moment(value1, 'MM/DD/YYYY').format('YYYY/MM/DD');
          var datevalue2 = moment(value2, 'MM/DD/YYYY').format('YYYY/MM/DD');

          result = ( datevalue1 <= datevalue2 ) ? -1 : (datevalue1 > datevalue2 ) ? 1 : 0;
        }

        // else if (event.field.toLocaleLowerCase() === 'currency' || event.field.toLocaleLowerCase() === 'decimal' || event.field.toLocaleLowerCase() === 'integer') {
          else if (!value1.match(/[a-z]/i) && !value2.match(/[a-z]/i)) {
          var floatvalue1 = parseFloat(value1);
          var floatvalue2 = parseFloat(value2);

          result = (floatvalue1 <= floatvalue2) ? -1 : (floatvalue1 > floatvalue2) ? 1 : 0;
        }

        else         
          result = value1.localeCompare(value2);
      }
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });


  }

  onDocumentTypeChange() {
    this.selectedDocumentType;
    if(this.selectedDocumentType !== null) {
      var routerUrl  = '/custom-webpage/' + this.selectedDocumentType.DocumentTypeURL ;
      this.router.navigate([routerUrl], {relativeTo: this.activatedRoute}); // when working with same route but different query params add 'this.router.routeReuseStrategy.shouldReuseRoute = () => false;' in constructor 
    }
  }

  private authenticateCustomPage() {
    this.loading = true;
    this.customLoader = true;
    this.docuwareCabinetService.authenticateCustomPage(this.forgotPasswordRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<InceptionCustomAuthResponseModel>) => {
        this.processResult<any>(result, () => {
          if (result.IsSuccess) {
            if (result.ResponseModel) {
              
              this.customAuthResponseModel = result.ResponseModel;

              if (this.customAuthResponseModel?.Company) {
                this.companyResponseModel = this.customAuthResponseModel.Company;
              }

              if (this.customAuthResponseModel?.DocumentType) {
                this.documentTypeResponseModel = this.customAuthResponseModel?.DocumentType;
                this.cardImageBase64 = 'data:image/png;base64,' + this.documentTypeResponseModel.LogoImage;
              }

              if (this.documentTypeResponseModel.LogoImage) {
                this.cardImageBase64 = 'data:image/png;base64,' + this.documentTypeResponseModel.LogoImage;
              }

              this.cabinetRequestModel = new DocuwareCabinetReuestModel();
              this.cabinetRequestModel.DocumentTypeId = this.customAuthResponseModel?.DocumentType?.DocumentTypeId;
              this.cabinetRequestModel.CabinetId = this.customAuthResponseModel?.DocumentType?.DocuwareCabinetId;
              this.cabinetRequestModel.OrganizationName = this.customAuthResponseModel?.Company?.DocuwareMasters[0]?.OrganizationName;
              this.cabinetRequestModel.ServerURL = this.customAuthResponseModel?.Company?.DocuwareMasters[0]?.ServerURL;
              this.cabinetRequestModel.UserName = this.customAuthResponseModel?.Company?.DocuwareMasters[0]?.UserName;
              this.cabinetRequestModel.Password = this.customAuthResponseModel?.Company?.DocuwareMasters[0]?.Password;
              this.cabinetRequestModel.PassPhrase = this.customAuthResponseModel?.Company?.DocuwareMasters[0]?.PassPhrase;
              this.cabinetRequestModel.PageNo = 1;
              this.cabinetRequestModel.PageSize = this.pageSize;

              if (this.companyResponseModel?.DocuwareMasters?.length > 0 && this.companyResponseModel?.InterfaceWith === enumDatabaseType.enumDocuware) {
                this.isDocuware = true;
                // this.getFileCabinetRecordsById();
                // if(this.cabinetRequestModel.ServerURL !== 'https://nashuanh.docuware.cloud'){
                //   this.getFileCabinetRecordsById();
                // }
                if (this.cabinetRequestModel.CabinetId == 'eb2769dc-2eb7-4e7b-a9d8-a9a49d2f1b38' 
                || this.cabinetRequestModel.CabinetId == '9e1b6b38-2ecc-4c18-b7f5-1a9592c1596e'
                || this.cabinetRequestModel.CabinetId == '40ed34b0-23b3-4fa3-9df6-acc88589364a') {
                  
                  this.getSearchableFieldsFromDb();
                  this.loading = false;
                  this.customLoader = false;
                }
                else {
                  this.forSepcialCompanyDb = false;
                  this.getSearchControlValues();
                }
              }
              else {
                // methods tp get app database records
                if (this.documentTypeResponseModel) {
                  this.forSepcialCompanyDb = false;
                  this.getHeadersForAppDb();
                  this.getAllDocumentTypeDocument();
                  this.getAllDocumentTypeList();
                }
              }

            } else {
              // this.loading = false;
              // this.customLoader = false;
            }
          }
          else {
            // this.loading = false;
            // this.customLoader = false;

            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        // this.loading = false;
        // this.customLoader = false;

        this.timeoutFunct(httpResponseError.message, false);
      });

  }

  getSearchableFieldsFromDb() {
    this.docuwareCabinetService.getSearchableFieldsByCabinetId(this.cabinetRequestModel.CabinetId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<SearchableFieldResponseModel[]>) => {
        this.processResult<any>(result, () => {
          if (result.IsSuccess) {

            if (result.ResponseModel) {
              this.searchableFieldModel = result.ResponseModel;
              if (this.searchableFieldModel.length > 0) {
                this.isSearchHasData = true;
                this.displayPagination = false;
                this.forSepcialCompanyDb = true;

                this.showSearchPanel = true;
              }
              else {
                this.isSearchHasData = false;

                this.showSearchPanel = false;
              }
            }
            this.buildForm();
            this.loading = false;
          }
          else {
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  private getHeadersForAppDb() {
    this.headers = [];
    if (this.documentTypeResponseModel && this.documentTypeResponseModel?.DocumentTypeMetadatas) {

      this.headers.push({ headerName: "DocumentId", fieldType: 'Numeric', sequence: -1 });
      this.headers.push({ headerName: 'View', fieldType: 'Text', sequence: 0 });

      this.documentTypeResponseModel.DocumentTypeMetadatas.forEach(metadata => {
        if (metadata.IsDisplay) {
          this.headers.push({ headerName: metadata.DisplayName, fieldType: metadata.FieldType, sequence: metadata.DisplaySequence });
        }

        if(metadata.DocumentTypeMetadataOptions.length > 0){
          
          this.filterDropdownSuggestion(metadata.DocumentTypeMetadataId, metadata.FieldName);
        }
      });
      this.headers = this.headers.sort((a, b) => a.sequence - b.sequence);
    }

    // if (this.documentTypeResponseModel?.DocumentTypeMetadatas.length == 0) {
    //   this.timeoutFunct('Please add document type fields', false);
    // }
  }

  // private _getAllDocumentTypeDocument() {

  //   this.documentCabinetService.GetAllDocumentTypeDocument(this.documentTypeResponseModel.DocumentTypeId)
  //     .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
  //     .subscribe((result: ResultOfT<DocumentTypeDocumentResponseModel[]>) => {
  //       this.processResult<DocumentTypeDocumentResponseModel[]>(result, () => {
  //         if (result.IsSuccess) {
  //           this.documents = result.ResponseModel;

  //           this.searchRecords = [];
  //           this.backupRecords = [];
  //           var searchControls = [];
  //           if (this.documents) {

  //             if (this.documents.length > 0) {
  //               // this.showSearchPanel = true;
                
  //               this.documents.forEach(document => {
  //                 if(document.IsPublic) {
  //                   const record = {};

  //                   record["DocumentTypeDocumentId"] = document.DocumentTypeDocumentId;
  
  //                   document.DocumentTypeDocumentMetadatas.forEach(metadata => {
  //                     if(metadata.DocumentTypeMetadata.FieldType === enumFieldTypes.enumDecimal) {
  //                       var parsedValue = Number(metadata.MetadataValue);
  //                       var formattedDecimal = parsedValue.toFixed(metadata.DocumentTypeMetadata.DecimalPlaces);
  //                       record[metadata.DocumentTypeMetadata.DisplayName] = formattedDecimal.toString();
  //                     } else {
  //                       record[metadata.DocumentTypeMetadata.DisplayName] = metadata.MetadataValue;
  //                     }
  //                   });
  //                   this.searchRecords.push(record);
  //                   searchControls.push(record);
  //                 }

  //               });

  //               this.backupRecords = this.searchRecords;

  //               // this.searchInputList = new Array<{ DisplayAs: '', FieldName: '', FieldType:'', Sequence: 0, Values: {label:'', value:''}[] }>();
  //               this.searchInputList = new Array<{ DisplayAs: '', FieldName: '', FieldType: '', Sequence: 0, Values: { label: '', value: '' }[], FilteredValues: { label: '', value: '' }[] }>();

  //               // const searchModel = this.documents[0].DocumentTypeDocumentMetadatas.filter(x => x.DocumentTypeMetadata.IsSearch === true);
  //               // if(searchModel?.length > 0) {
  //               
  //               //   searchModel.forEach(item => {
  //               //     this.searchInputList.push( { DisplayAs: item.DocumentTypeMetadata.DisplayName , FieldName: item.DocumentTypeMetadata.FieldName, FieldType: item.DocumentTypeMetadata.FieldType, Sequence: item.DocumentTypeMetadata.SearchSequence , Values: null});
  //               //   });
  //               // }

  //               const searchModel = this.documentTypeResponseModel.DocumentTypeMetadatas.filter(x => x.IsSearch === true);
  //               if (searchModel?.length > 0) {
  //                 searchModel.forEach(item => {
  //                   // this.searchInputList.push( { DisplayAs: item.DisplayName, FieldName: item.FieldName, FieldType: item.FieldType, Sequence: item.SearchSequence, Values: null} );
  //                   // this.searchInputList.push({ DisplayAs: item.DisplayName, FieldName: item.FieldName, FieldType: item.FieldType, Sequence: item.SearchSequence, Values: null, FilteredValues: null });
                    
  //                 });
  //               }

  //               this.searchInputList.forEach(item => {
  //                 item.Values = new Array<{ label: '', value: '' }>();

  //                 searchControls.forEach(rec => {
  //                   //var x = rec[item.DisplayAs];

  //                   if (rec[item.DisplayAs] !== undefined) {
  //                     var repeated = item.Values.filter(x => x.label.toLowerCase().trim() == rec[item.DisplayAs].toLowerCase().trim());
  //                     if (rec[item.DisplayAs] !== null && rec[item.DisplayAs] !== '' && rec[item.DisplayAs] !== ' ' && repeated.length < 1) {
  //                       item.Values.push({ label: rec[item.DisplayAs], value: rec[item.DisplayAs] });
  //                     }
  //                   }
  //                 });

  //                 item.Values = item.Values.sort((a, b) => {
  //                   return a.label.toLowerCase().trim().localeCompare(b.label.toLowerCase().trim());
  //                 });

  //               });

  //           this.searchInputList = this.searchInputList.sort((a,b) => a.Sequence - b.Sequence);
  //           // this.searchRecords = [];

  //           if(this.searchRecords?.length > 0 && this.searchInputList?.length > 0) {
  //             this.showSearchPanel = true;
  //           }
  //         }
  //       }
  //       // console.clear();
  //       // console.log("Search records");
  //       // console.log(this.searchRecords);
  //       // console.log("Search input list");
  //       // console.log(this.searchInputList);
  //       // console.log("Search filter");
  //       // console.log(this.searchFilterData)
  //       // console.log("Headers");
  //       // console.log(this.headers);

  //       // console.log("documents");
  //       // console.log(this.documents);
  //       // console.log("document type response model");
  //       // console.log(this.documentTypeResponseModel);
        
  //           this.buildForm();
  //           this.searchLoader = false;
  //           this.customLoader = false;
  //         } else {
  //           this.timeoutFunct(result.Message, false);
  //         }
  //       });
  //     }, (httpResponseError) => {
  //       this.timeoutFunct(httpResponseError.message, false);
  //     });
  //   this.loading = false;
  // }

  private getAllDocumentTypeDocument() {

    this.loading = true;
    var isCustomPage = true;
    this.documentCabinetService.GetRawDataForDynamicObject(this.documentTypeResponseModel.DocumentTypeId, isCustomPage)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<DocumentTypeDocumentModelForSearch>) => {
        this.processResult<DocumentTypeDocumentModelForSearch>(result, () => {
          this.loading = true;
          if (result.IsSuccess) {
            // this.documents = result.ResponseModel;
            this.searchRecords = [];
            this.backupRecords = [];
            var searchControls = [];
            

            if(result.ResponseModel ) {
              if(result.ResponseModel.Documents) {
                this.documents = result.ResponseModel.Documents;
              }
              if (this.documents?.length > 0) {
                this.showSearchPanel = true;
              }

              if(result.ResponseModel.BackupSearchRecords) {
                this.backupRecords = result.ResponseModel.BackupSearchRecords;
                this.searchRecords = result.ResponseModel.BackupSearchRecords;
                this.backupSearchRecords = result.ResponseModel.BackupSearchRecords;
              }

              if(result.ResponseModel.SearchInput) {
                this.searchInputList = new Array<SearchInputModel>();
                this.searchInputList = result.ResponseModel.SearchInput;
                // console.log("searchInputList");
                // console.log(this.searchInputList);
              }
            }
            
              // console.clear();
              // console.log('search records');
              // console.log(this.searchRecords);
              // console.log('backup search records');
              // console.log(this.backupSearchRecords);
              // console.log('Search input list');
              // console.log(this.searchInputList);
            this.buildForm();
            this.loading = false;
          } else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  private getAllDocumentTypeList() {
    this.loading = true;
    this.documentCabinetService.GetAllDocumentTypeList(this.documentTypeResponseModel.DocumentCabinetId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<DocumentTypeListResponseModel[]>) => {
        this.processResult<DocumentTypeListResponseModel[]>(result, () => {
          if (result.IsSuccess) {
            this.documentTypeList = result.ResponseModel;

            if(this.documentTypeResponseModel && this.documentTypeList.length > 0) {
              this.selectedDocumentType = new DocumentTypeListResponseModel();
              this.selectedDocumentType = this.documentTypeList.find(x => x.DocumentTypeId == this.documentTypeResponseModel.DocumentTypeId);
              // this.showSearchPanel = true;
            }

            this.searchLoader = false;
            this.customLoader = false;
          } else {
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.timeoutFunct(httpResponseError.message, false);
      });
    this.loading = false;
  }

  private getFileCabinetRecordsById() {

    this.loading = true;
    this.customLoader = true;

    // this.docuwareService.getFileCabinetRecordsById(this.cabinetRequestModel)
    this.docuwareCabinetService.getDocuwareFileCabinetRecords(this.cabinetRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<DocuwareRecordsModel[]>) => {
        this.processResult<any>(result, () => {
          if (result.IsSuccess) {
            if (result.ResponseModel) {

              this.docuwareRecordsModel = result.ResponseModel;
              this.docuwareRecordsBackup = this.docuwareRecordsModel;

              this.headers = [];
              this.records = [];
              this.searchRecords = [];

              this.docuwareRecordsModel.forEach(docuwareRecord => {
                const record = {};

                record["DocumentId"] = docuwareRecord.DocuwareDocumentId;


                docuwareRecord.DocuwareFields.forEach(field => {

                  record[field.DisplayAs] = field.FieldValue;

                });

                this.records.push(record);
              });

              this.searchRecords = this.records;
              this.backupRecords = this.records;

              this.headers.push({ headerName: "DocumentId", fieldType: 'Numeric', sequence: -1 });
              this.headers.push({ headerName: 'View', fieldType: 'Text', sequence: 0 });
              this.docuwareRecordsModel[0]?.DocuwareFields.forEach(field => {
                if (field.IsDisplay) {

                  this.headers.push({ headerName: field.DisplayAs, fieldType: field.FieldType, sequence: field.DisplaySequence });
                }
              });

              this.headers = this.headers.sort((a, b) => a.sequence - b.sequence);

              this.displayPagination = true;

              this.startRecord = ((this.cabinetRequestModel.PageNo - 1) * (this.pageSize)) + 1;
              // this.endRecord = this.startRecord + this.pageSize -1;
              this.endRecord = this.startRecord + this.searchRecords.length - 1;

              if (this.pageCount == this.paginationPage) {
                this.endRecord = this.totalRecordCount;
              }

            }
            this.loading = false;
            this.customLoader = false;
            // this.buildForm();
          }
          else {
            this.loading = false;
            this.customLoader = false;

            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.customLoader = false;

        this.timeoutFunct(httpResponseError.message, false);
      });
  }


  changePage(pageNumber: string) {

    // this.cabinetRequestModel.PageNo = pageNumber;
    pageNumber;

    this.paginationPage = parseInt($('.cdp').attr('actpage'), 10);

    if (pageNumber === '+1') {
      this.paginationPage++;
    } else if (pageNumber === '-1') {
      this.paginationPage--;
    } else {
      this.paginationPage = parseInt(pageNumber, 10);
    }
    $('.cdp').attr('actpage', this.paginationPage);


    this.cabinetRequestModel.PageNo = this.paginationPage;
    this.getFileCabinetRecordsById();
  }

  private getSearchControlValues() {
    this.loading = true;
    this.customLoader = true;

    this.docuwareCabinetService.getSearchControlValue(this.cabinetRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<DocuwareRecordsModel[]>) => {
        this.processResult<any>(result, () => {
          if (result.IsSuccess) {
            if (result.ResponseModel) {

              this.docuwareSearchControlModel = result.ResponseModel;
              if (this.docuwareSearchControlModel.length > 0) {

                this.showSearchPanel = true;
                this.totalRecordCount = this.docuwareSearchControlModel[0].TotalRecordCount;
                this.pageCount = Math.ceil(this.totalRecordCount / this.pageSize);

                this.pageArray = new Array<number>();
                this.pageArray = Array(this.pageCount);
              }

              var searchControls = [];

              this.docuwareSearchControlModel.forEach(docuwareRecord => {
                const record = {};

                record["DocumentId"] = docuwareRecord.DocuwareDocumentId;


                docuwareRecord.DocuwareFields.forEach(field => {

                  record[field.DisplayAs] = field.FieldValue;

                });

                searchControls.push(record);
              });


              const searchModel = this.docuwareSearchControlModel[0]?.DocuwareFields.filter(x => x.IsSearch == true);
              
              // // this.searchInputList = new Array<{ DisplayAs: '', FieldName: '', FieldType:'', Sequence: 0, Values: {label:'', value:''}[] }>();
              // // this.searchInputList.push({DisplayAs:'Full Text', FieldName: "FULL_TEXT", FieldType: "Text", Sequence: 0, Values: null });
              // this.searchInputList = new Array<{ DisplayAs: '', FieldName: '', FieldType: '', Sequence: 0, Values: { label: '', value: '' }[], FilteredValues: { label: '', value: '' }[] }>();
              // this.searchInputList.push({ DisplayAs: 'Full Text', FieldName: "FULL_TEXT", FieldType: "Text", Sequence: 0, Values: null, FilteredValues: null });
              // if (searchModel?.length > 0) {

              //   searchModel.forEach(item => {
              //     this.searchInputList.push({ DisplayAs: item.DisplayAs, FieldName: item.FieldName, FieldType: item.FieldType, Sequence: item.SearchSequence, Values: null, FilteredValues: null });
              //   });
              // }


              this.searchInputList = new Array<SearchInputModel>();
              var forFulltext = new SearchInputModel();

              forFulltext.DisplayAs = 'Full Text';
              forFulltext.FieldName = 'FULL_TEXT';
              forFulltext.FieldType = 'Text';
              forFulltext.Sequence = 0;

              this.searchInputList.push(forFulltext);
              if (searchModel?.length > 0) {

                searchModel.forEach(item => {
                  var searchInput = new SearchInputModel();
                  searchInput.DisplayAs = item.DisplayAs;
                  searchInput.FieldName = item.FieldName;
                  searchInput.FieldType = item.FieldType;
                  searchInput.Sequence = item.SearchSequence;

                  this.searchInputList.push(searchInput);

                });
              }

              this.searchInputList.forEach(item => {
                item.Values = new Array<{ label: '', value: '' }>();

                if (item.DisplayAs !== "Full Text") {

                  searchControls.forEach(rec => {
                    var x = rec[item.DisplayAs];

                    var repeated = item.Values.filter(x => x.label.toLowerCase().trim() == rec[item.DisplayAs].toLowerCase().trim());
                    if (rec[item.DisplayAs] !== null && rec[item.DisplayAs] !== '' && rec[item.DisplayAs] !== ' ' && repeated.length < 1) {
                      item.Values.push({ label: rec[item.DisplayAs], value: rec[item.DisplayAs] });

                    }
                  });

                }
                item.Values = item.Values.sort((a, b) => {
                  return a.label.toLowerCase().trim().localeCompare(b.label.toLowerCase().trim());
                });
              });

              this.searchInputList = this.searchInputList.sort((a, b) => a.Sequence - b.Sequence);

            }
            this.loading = false;
            this.customLoader = false;

            //console.log("Search input");
            //console.log(this.searchInputList);

            this.displayPagination = false;
            // if (this.cabinetRequestModel.ServerURL === 'https://nashuanh.docuware.cloud') 
            // {
            //   this.displayPagination = false;
            // }
            this.buildForm();
          }
          else {
            this.loading = false;
            this.customLoader = false;

            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.customLoader = false;

        this.timeoutFunct(httpResponseError.message, false);
      });


  }

  callFunction(stuff) {

    if (this.searchLoader) {
      // this.loading = false;
      // this.customLoader = false;

      this.searchLoader = false;
    }
  }
  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage = true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup() {
    this.displayMessage = false;
  }


  private buildForm() {

    if (this.form) {
      this.form = new FormGroup({});
    }

    const formGroup = this.formBuilder.group({});
    var that = this;
    var count = 0;

    if (this.isDocuware) {

      if (this.searchableFieldModel != null) {

        this.searchableFieldModel.forEach(field => {
          formGroup.addControl(field.FieldName, that.formBuilder.control(null,
            {
              updateOn: Constants.formControlUpdateStrategy.change
            }));
          count += 1;
        });
      }

      else {
        formGroup.addControl("FULL_TEXT", that.formBuilder.control(null,
          {
            updateOn: Constants.formControlUpdateStrategy.change
          }));
        if (this.docuwareSearchControlModel[0]?.DocuwareFields) {

          this.docuwareSearchControlModel[0].DocuwareFields.forEach(field => {
            if (field.IsSearch) {
              formGroup.addControl(field.FieldName, that.formBuilder.control(null,
                {
                  updateOn: Constants.formControlUpdateStrategy.change
                }));
              count += 1;
            }
          });
        }
      }
    } else {
      if (this.documentTypeResponseModel?.DocumentTypeMetadatas.length > 0) {
        formGroup.addControl("FULL_TEXT", that.formBuilder.control(null,
          {
            updateOn: Constants.formControlUpdateStrategy.change
          }));
        this.documentTypeResponseModel.DocumentTypeMetadatas.forEach(field => {
          if (field.IsSearch) {
            if(field.FieldType === enumFieldTypes.enumInteger || field.FieldType === enumFieldTypes.enumDate 
              || field.FieldType === enumFieldTypes.enumDecimal || field.FieldType === enumFieldTypes.enumCurrency ) {
            formGroup.addControl(field.FieldName + 'From', that.formBuilder.control(null,
              {
                updateOn: Constants.formControlUpdateStrategy.change
              }));

              formGroup.addControl(field.FieldName + 'To', that.formBuilder.control(null,
                {
                  updateOn: Constants.formControlUpdateStrategy.change
                }));

            count += 2;
            } 
            else {
              formGroup.addControl(field.FieldName, that.formBuilder.control(null,
                {
                  updateOn: Constants.formControlUpdateStrategy.change
                }));
              count += 1;
            }
            
          }
        });
        // console.log("Form controls");
        // console.log(formGroup);
      }
    }

    this.form = formGroup;
  }


  DocumentViewerModel: boolean;

  DocumentViewerDialog() {
    this.DocumentViewerModel = true;
  }

}
