import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CompanyRequestModel } from 'src/app/models/request/company-request';
import { DocuwareMasterRequestModel } from 'src/app/models/request/docuware-master-request';
import { TestDocuwareConnectionModel } from 'src/app/models/request/test-docuware-connection-model';
import { CompanyResponseModel } from 'src/app/models/response/company-response';
import { ResultOfT } from 'src/app/models/response/result';
import { CompanyService } from 'src/app/services/company.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { CommonFunctions } from 'src/app/shared/common-functions';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { enumDatabaseType } from 'src/app/shared/enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-manage',
  templateUrl: './company-manage.component.html',
  styleUrls: ['./company-manage.component.sass']
})
export class CompanyManageComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges 
{

  @Output() isCompanyListUpdated = new EventEmitter<boolean>();
  
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input('companyId') companyId: number;
  @Input('count') count: number;

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  companyResponseModel: CompanyResponseModel;
  testConnectionModel: TestDocuwareConnectionModel;
  isSelectedDbDocuware: boolean = false;

  isEditMode: boolean = false;
  displayMessage: boolean;
  messageClass: boolean;
  message: any;

  OrganizationInformation: boolean =false;
  isActive: boolean = true;
  OrganizationContactInformation: boolean = false;
  Integration: boolean = false;

  enumDatabaseType = enumDatabaseType;
  databaseOptions: any[];
  showStorageInput: boolean=false;
  ManageCompany: boolean = false;

  constructor(private formBuilder: FormBuilder, private companyService: CompanyService,private activatedRoute: ActivatedRoute,
    private router: Router) { 
    super();

  }

  formFields = {
    customerOrganizationName: 'customerOrganizationName',
    websiteURL: 'websiteURL',
    contactFirstName: 'contactFirstName',
    contactLastName: 'contactLastName',
    contactEmail: 'contactEmail',
    contactPhone: 'contactPhone',
    documentIntegration: 'documentIntegration',
    allowedStorage: 'allowedStorage',
    organizationName: 'organizarionName',
    serverURL: 'serverURL',
    userName: 'userName',
    password: 'password',
    passphrase: 'passphrase',
    active:'active',
  };

  validationMessages = {
    customerOrganizationName: {
      required: 'Customer organization name is required',
      validCharacters: 'Enter valid customer organization name'
    },
    websiteURL: {
      required: 'Website URL is required',
    },
    contactFirstName: {
      required: 'Contact first name is required',
      validCharacters: 'Enter valid contact first name'
    },
    contactLastName: {
      required: 'Contact last name is required',
      validCharacters: 'Enter valid contact last name'
    },
    contactEmail: {
      required: 'Contact email is required',
      email: 'Enter valid contact email'
    },
    contactPhone: {
      required: 'Contact phone is required',
      validNumber: 'Enter valid contact phone',
      minlength: 'Phone number should be 10 digits'
    },
    documentIntegration: {
      required: 'Document integration is required',
    },
    allowedStorage: {
      required : 'Storage is required',
      validNumber: 'Enter valid storage'
    },
    organizationName: {
      required: 'Organization name is required',
    },
    serverURL: {
      required: 'URL is required',
    },
    userName: {
      required: 'User name is required',
      //validCharacters: 'Enter valid user name',
      // email: 'Enter valid username'
    },
    password: {
      required: 'Password is required',
    },
    passphrase: {
      required: 'Passphrase is required',
    },
  };
  
  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }


  ngOnInit(): void {
    this.buildForm();

    this.databaseOptions = CommonFunctions.enumSelector(this.enumDatabaseType);

    $(function(){
      var appendthis =  ("<div class='modal-overlay js-modal-close'></div>");
        $('a[data-modal-id]').click(function(e) {
          e.preventDefault();
          $("body").append(appendthis);
          $(".modal-overlay").fadeTo(500, 0.7);
          var modalBox = $(this).attr('data-modal-id');
          $('#'+modalBox).fadeIn($(this).data());
        });


      $(window).resize(function() {
          $(".modal-box").css({
              top: ($(window).height() - $(".modal-box").outerHeight()) / 2,
              left: ($(window).width() - $(".modal-box").outerWidth()) / 2
          });
      });

      $(window).resize();

      });
  }

  ngOnChanges(changes: SimpleChanges) {
    
    if (this.companyId > 0) {
      this.isEditMode = true;
      this.getCompanyById(this.companyId);
    } else{
      this.resetCompanyForm();
    }
  }

  OrganizationInformationClick(){
    this.OrganizationInformation = !this.OrganizationInformation;
  }
  OrganizationContactInformationClick(){
    this.OrganizationContactInformation = !this.OrganizationContactInformation;
  }

  IntegrationClick(){
    this.Integration = !this.Integration;
  }

  onSelectChange(event){
    
    this.showStorageInput = true;
    if(event.value == enumDatabaseType.enumDocuware){
      this.isSelectedDbDocuware = true;
    } else {
      this.isSelectedDbDocuware = false;
    }

    this.updateValidationForDocuware();
  }

  onCompanyChangeInHeader(company: any){
    this.router.navigate(['/company/edit/' + company.CompanyId]);
    this.getCompanyById(company.CompanyId);
  }

  updateValidationForDocuware(){


    if(this.isSelectedDbDocuware) {    

      this.form.get(this.formFields.organizationName).setValidators([Validators.required]);
      this.form.get(this.formFields.organizationName).updateValueAndValidity;

      this.form.get(this.formFields.serverURL).setValidators([Validators.required]);
      this.form.get(this.formFields.serverURL).updateValueAndValidity();

      //this.form.get(this.formFields.userName).setValidators([Validators.required, CustomValidator.userNameDocuware]);
      this.form.get(this.formFields.userName).setValidators([Validators.required]);
      this.form.get(this.formFields.userName).updateValueAndValidity();

      this.form.get(this.formFields.password).setValidators([Validators.required]);
      this.form.get(this.formFields.password).updateValueAndValidity();

      this.form.get(this.formFields.passphrase).setValidators([Validators.required]);
      this.form.get(this.formFields.passphrase).updateValueAndValidity();

      this.form.controls[this.formFields.allowedStorage].clearValidators();
      this.form.controls[this.formFields.allowedStorage].updateValueAndValidity();
    } 
    else {
      this.form.controls[this.formFields.organizationName].clearValidators();
      this.form.controls[this.formFields.organizationName].updateValueAndValidity();

      this.form.controls[this.formFields.serverURL].clearValidators();
      this.form.controls[this.formFields.serverURL].updateValueAndValidity();

      this.form.controls[this.formFields.userName].clearValidators();
      this.form.controls[this.formFields.userName].updateValueAndValidity();

      this.form.controls[this.formFields.password].clearValidators();
      this.form.controls[this.formFields.password].updateValueAndValidity();

      this.form.controls[this.formFields.passphrase].clearValidators();
      this.form.controls[this.formFields.passphrase].updateValueAndValidity();

      this.form.controls[this.formFields.allowedStorage].setValidators([Validators.required, CustomValidator.onlyNumbers]);
      this.form.controls[this.formFields.allowedStorage].updateValueAndValidity();
    } 
    
  }

  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage =true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup(){
    this.displayMessage = false;
  }

  getValuesFromForm(){
    const form = this.form;

    const companyRequestModel = new CompanyRequestModel();

    companyRequestModel.CompanyId = this.isEditMode ? this.companyResponseModel.CompanyId : 0;
    companyRequestModel.CompanyName = form.get(this.formFields.customerOrganizationName).value.toString().trim();
    companyRequestModel.CompanyWebsiteURL = form.get(this.formFields.websiteURL).value.toString().trim();
    companyRequestModel.ContactFirstName = form.get(this.formFields.contactFirstName).value.toString().trim();
    companyRequestModel.ContactLastName = form.get(this.formFields.contactLastName).value.toString().trim();
    companyRequestModel.ContactEmail = form.get(this.formFields.contactEmail).value.toString().trim();
    companyRequestModel.ContactPhone = form.get(this.formFields.contactPhone).value;


    if(this.isSelectedDbDocuware) {
      
      companyRequestModel.DocuwareMasters = new Array<DocuwareMasterRequestModel>();
      var docuwareMaster = new DocuwareMasterRequestModel();

      docuwareMaster.CompanyId = this.isEditMode ? this.companyResponseModel.CompanyId : 0;
      docuwareMaster.DocuwareId = this.isEditMode ? this.companyResponseModel?.DocuwareMasters[0]?.DocuwareId : 0;
      docuwareMaster.OrganizationName = form.get(this.formFields.organizationName).value;
      docuwareMaster.ServerURL = form.get(this.formFields.serverURL).value;
      docuwareMaster.UserName = form.get(this.formFields.userName).value;
      docuwareMaster.Password = form.get(this.formFields.password).value;
      docuwareMaster.PassPhrase = form.get(this.formFields.passphrase).value;
      docuwareMaster.IsActive = this.isActive;

      companyRequestModel.DocuwareMasters.push(docuwareMaster);

      companyRequestModel.InterfaceWith = this.enumDatabaseType.enumDocuware;
    } else {
      companyRequestModel.InterfaceWith = this.enumDatabaseType.enumInApp;
      companyRequestModel.AllowedDocumentStorage = form.get(this.formFields.allowedStorage).value;
    }

    companyRequestModel.IsActive = this.isActive;

    return companyRequestModel;

  }

  setValuesToForm() {
    this.form.reset();

    if(!this.companyResponseModel){
      return;
    }

    const form = this.form;
    this.isSelectedDbDocuware = false;

    form.get(this.formFields.customerOrganizationName).setValue(this.companyResponseModel.CompanyName);
    form.get(this.formFields.websiteURL).setValue(this.companyResponseModel.CompanyWebsiteURL);
    form.get(this.formFields.contactFirstName).setValue(this.companyResponseModel.ContactFirstName);
    form.get(this.formFields.contactLastName).setValue(this.companyResponseModel.ContactLastName);
    form.get(this.formFields.contactEmail).setValue(this.companyResponseModel.ContactEmail);
    form.get(this.formFields.contactPhone).setValue(this.companyResponseModel.ContactPhone);
    form.get(this.formFields.documentIntegration).setValue(this.companyResponseModel.InterfaceWith);

    var category = this.databaseOptions.find(where => where.title == this.companyResponseModel.InterfaceWith);

    form.get(this.formFields.documentIntegration).setValue(category.title);

    this.isActive = this.companyResponseModel.IsActive;
    
    if(this.companyResponseModel.DocuwareMasters.length > 0) {
      form.get(this.formFields.organizationName).setValue(this.companyResponseModel?.DocuwareMasters[0]?.OrganizationName);
      form.get(this.formFields.serverURL).setValue(this.companyResponseModel?.DocuwareMasters[0]?.ServerURL);
      form.get(this.formFields.userName).setValue(this.companyResponseModel?.DocuwareMasters[0]?.UserName);
      form.get(this.formFields.password).setValue(this.companyResponseModel?.DocuwareMasters[0]?.Password);
      form.get(this.formFields.passphrase).setValue(this.companyResponseModel?.DocuwareMasters[0]?.PassPhrase);
      this.isSelectedDbDocuware = true;
      
    } else {
      this.showStorageInput = true;
      // // enable this after service logic's been implemented
      this.form.controls[this.formFields.allowedStorage].setValidators([Validators.required, CustomValidator.onlyNumbers]);
      this.form.controls[this.formFields.allowedStorage].updateValueAndValidity();
      if(this.companyResponseModel.AllowedDocumentStorage) {
        form.get(this.formFields.allowedStorage).setValue(this.companyResponseModel.AllowedDocumentStorage);
      }
    }
  }

  testConnection(){

    var touchedCount = 0
    if(this.form.get(this.formFields.organizationName).invalid){
      this.form.get(this.formFields.organizationName).markAsTouched();
      touchedCount++;
    }
    if(this.form.get(this.formFields.serverURL).invalid){
      this.form.get(this.formFields.serverURL).markAsTouched();
      touchedCount++;
    }
    if(this.form.get(this.formFields.userName).invalid){
      this.form.get(this.formFields.userName).markAsTouched();
      touchedCount++;
    }
    if(this.form.get(this.formFields.password).invalid){
      this.form.get(this.formFields.password).markAsTouched();
      touchedCount++;
    }

    if(touchedCount > 0){
      return;
    }

    this.testConnectionModel = new TestDocuwareConnectionModel();
    this.testConnectionModel.OrganizationName = this.form.get(this.formFields.organizationName).value;
    this.testConnectionModel.ServerURL = this.form.get(this.formFields.serverURL).value;
    this.testConnectionModel.UserName = this.form.get(this.formFields.userName).value;
    this.testConnectionModel.Password = this.form.get(this.formFields.password).value;

    this.companyService.testDocuwareConnection(this.testConnectionModel)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<any>) => {
      this.processResult<any>(result, () => {
        if(result.IsSuccess){
          this.timeoutFunct(result.Message, true);
        } else {
          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.timeoutFunct(httpResponseError.message, false);
    });

  }

  onSubmit(){
    

    if(this.form.invalid){
      this.markFormAsTouched();
      return;
    }

    const companyRequestModel = this.getValuesFromForm();
    this.loading = true;

    const manageCompany = this.isEditMode ? this.companyService.updateCompany(companyRequestModel)
    : this.companyService.addCompany(companyRequestModel) ;

    manageCompany
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<any>) => {
      this.processResult<any>(result, () => {
        if(result.IsSuccess){

          if(result.ResponseModel) {
            var response = result.ResponseModel;
          }
          
          var message = this.isEditMode ? 'Company information updated' : 'Company information added';
          this.loading = false;
          this.timeoutFunct(message, true);     
          setTimeout(() => { 
            
            this.form.reset();
            this.isCompanyListUpdated.emit(true); 
            this.closeDeletePopup();
            this.closePopup();     
          }, environment.messageLifeSpan);             

        } else {
          this.loading = false;
          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.loading = false;
      this.timeoutFunct(httpResponseError.message, false);
    });

  }

  private getCompanyById(companyId: number) {
    if (!companyId) {
      return;
    }

    this.isEditMode = true;
    this.loading = true;

    this.companyService.getCompanyById(companyId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<CompanyResponseModel>) => {
        this.processResult<CompanyResponseModel>(result, () => {
          if(result.IsSuccess){
            this.companyResponseModel = result.ResponseModel;
            this.setValuesToForm();
          } else {
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  private buildForm(){

    const formGroup = this.formBuilder.group({});

     formGroup.addControl(this.formFields.customerOrganizationName, this.formBuilder.control('',
     { validators: [Validators.required, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.websiteURL, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.contactFirstName, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.contactLastName, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.contactEmail, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.contactPhone, this.formBuilder.control('',
      { validators: [Validators.required, Validators.minLength(10)], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.documentIntegration, this.formBuilder.control('',
      { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

      formGroup.addControl(this.formFields.allowedStorage, this.formBuilder.control('',
      { updateOn: Constants.formControlUpdateStrategy.blur }));

   
    formGroup.addControl(this.formFields.organizationName, this.formBuilder.control('',
      {  updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.serverURL, this.formBuilder.control('',
      { updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.userName, this.formBuilder.control('',
      { updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.password, this.formBuilder.control('',
      { updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.passphrase, this.formBuilder.control('',
      { updateOn: Constants.formControlUpdateStrategy.change }));
    // formGroup.addControl(this.formFields.organizationName, this.formBuilder.control('',
    //   { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    // formGroup.addControl(this.formFields.serverURL, this.formBuilder.control('',
    //   { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    // formGroup.addControl(this.formFields.userName, this.formBuilder.control('',
    //   { validators: [Validators.required, CustomValidator.userNameDocuware], updateOn: Constants.formControlUpdateStrategy.change }));
    // formGroup.addControl(this.formFields.password, this.formBuilder.control('',
    //   { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    // formGroup.addControl(this.formFields.passphrase, this.formBuilder.control('',
    //   { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));

    formGroup.addControl(this.formFields.active, this.formBuilder.control('',
      {updateOn: Constants.formControlUpdateStrategy.change }));


    this.form = formGroup;
  }

  closeDeletePopup() {
    this.form.reset();
    this.isSelectedDbDocuware = false;
    this.showStorageInput = false;;
    $('.add-update-popup').fadeOut('fast');
  }

  closePopup() {
    this.close.emit();
    this.resetCompanyForm();
    $('.add-update-popup').fadeOut('fast');
  }

  resetCompanyForm() {
    this.ManageCompany = false; 
    if(this.form)    {
      this.form.reset();      
    }
    this.isSelectedDbDocuware = false;
    this.showStorageInput = false;
    this.isEditMode = false;
  }

}

