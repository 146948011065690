import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserAuthResponseModel } from 'src/app/models/response/access-token-response';
import { MigrationClientResponseModel } from 'src/app/models/response/migration-client-response';
import { ResultOfT } from 'src/app/models/response/result';
import { MigrationClientService } from 'src/app/services/migration-client.service';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { UserRole, enumMigrationClientRole, enumMigrationClientStatus } from 'src/app/shared/enum';
import { environment } from 'src/environments/environment';
import * as $ from "jquery";

@Component({
  selector: 'app-migration-client',
  templateUrl: './migration-client.component.html',
  styleUrls: ['./migration-client.component.sass']
})
export class MigrationCLientComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  displayMessage: boolean;
  messageClass: boolean;
  message: any;
  showClearFilter: boolean = false;

  filterResposniveEvent: any;

  currentUserToken: UserAuthResponseModel;
  isCustomerAdmin: boolean = false;
  companyId: number;

  clients: MigrationClientResponseModel[];
  clientsBackup: MigrationClientResponseModel[];
  selectedClientId: number;

  manageClient: boolean = false;
  clientCount: number;
  selectedStatus: any;
  selectedRole: any;
  displayClearButton: boolean = true;
  statusList: any;
  roleList: any;
  searchText: string;
  noRecordFound: boolean = false;
  DeletePopup: boolean = false;

  deleteClient: MigrationClientResponseModel;
  ResendLink: boolean = false;
  selectedUser: MigrationClientResponseModel;
  selectedEmail: string;


  constructor(private migrationClientService: MigrationClientService, private webStorageService: WebStorageService,
    private router: Router) {
    super();

    //need to set permissions
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
    this.companyId = this.currentUserToken.companyId;
  }

  ngOnInit(): void {

    this.populateClientList();

    this.clients = new Array<MigrationClientResponseModel>();
    this.selectedClientId = 0;

    this.roleList = Object.keys(enumMigrationClientRole).map(key => ({ label: enumMigrationClientRole[key], value: key }));
    this.roleList = this.roleList.filter(x => x.label !== enumMigrationClientRole.enumNone);

    this.statusList = Object.keys(enumMigrationClientStatus).map(key => ({ label: enumMigrationClientStatus[key], value: key }));
    this.statusList = this.statusList.filter(x => x.label !== enumMigrationClientStatus.enumNone);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  populateClientList() {
    
    if (this.currentUserToken.role === UserRole.enumCustomerAdmin) {
      this.isCustomerAdmin = true;
      this.getAllMigrationClients();
    }
    else if (this.currentUserToken.role === UserRole.enumAdmin) {
      this.getAllMigrationClients();
    }
    else {
      this.router.navigate(['/404']);
    }
  }

  getAllMigrationClients() {
    
    this.loading = true;
    this.migrationClientService.getAllMigrationClientInfo()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<MigrationClientResponseModel[]>) => {
        this.processResult<any>(result, () => {
          // console.log(result);
          if (result.IsSuccess) {
            
            if (result.ResponseModel) {

              this.clients = result?.ResponseModel;

              this.clientsBackup = this.clients;


            }
          }
          else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  private getMigrationClientById(clientId: number) {
    if (!clientId) {
      this.timeoutFunct('No client found', false);
      return;
    }
    this.loading = true;

    this.migrationClientService.getMigrationClientInfo(clientId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<MigrationClientResponseModel>) => {
        this.processResult<MigrationClientResponseModel>(result, () => {
          if (result.IsSuccess) {
            if (result.ResponseModel) {
              this.clients.push(result.ResponseModel);
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage = true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup() {
    this.displayMessage = false;
  }
  onAddIconClick(clientID: number) {
    
    this.manageClient = true;
    this.clientCount++;
    this.selectedClientId = clientID;
    // $('.add-update-popup').fadeIn('fast');

    // $('.page-wrapper').removeClass('active-sidebar')
  }

  filterClickEvent() {
    this.filterResposniveEvent = !this.filterResposniveEvent;
  }

  resetFilter() {
    this.selectedStatus = null;
    this.selectedRole = null;
    this.selectedClientId = null;
    this.showClearFilter = false;
    this.clients = this.clientsBackup;
    this.displayClearButton = false;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  onSearchChange(searchText) {
    
    var searchText = searchText.toLowerCase();
    if (this.selectedRole || this.selectedStatus) {
      this.displayClearButton = true;
    } else {
      this.displayClearButton = false;
    }
    if (!searchText && (this.selectedRole || this.selectedStatus))
      this.filterResposniveEvent = !this.filterResposniveEvent;
    this.searchByStatusAndRole(searchText, this.clientsBackup);
  }

  searchByStatusAndRole(searchText, clients: any) {
   
    if (this.selectedStatus == enumMigrationClientStatus.enumActive) {
      if (searchText.length > 0) {
        if (this.selectedRole) {
          this.clients = clients.filter(where => where.IsActive == true && where.Role == this.selectedRole &&
            (where.FirstName.toLowerCase().includes(searchText) || where.LastName.toLowerCase().includes(searchText)
              || where.Email.toLowerCase().includes(searchText)));

          this.noRecordFound = this.clients.length < 1 ? true : false;
        } else {
          this.clients = clients.filter(where => where.IsActive == true &&
            (where.FirstName.toLowerCase().includes(searchText) || where.LastName.toLowerCase().includes(searchText)
              || where.Email.toLowerCase().includes(searchText)));

          this.noRecordFound = this.clients.length < 1 ? true : false;
        }
      }
      else {
        if (this.selectedRole) {
          this.clients = clients.filter(where => where.IsActive == true && where.Role == this.selectedRole);

          this.noRecordFound = this.clients.length < 1 ? true : false;
        } else {
          this.clients = clients.filter(where => where.IsActive == true);

          this.noRecordFound = this.clients.length < 1 ? true : false;
        }
      }

    }
    if (this.selectedStatus == enumMigrationClientStatus.enumInactive) {
      if (searchText.length > 0) {
        if (this.selectedRole) {
          this.clients = clients.filter(where => where.IsActive == false && where.Role == this.selectedRole &&
            (where.FirstName.toLowerCase().includes(searchText) || where.LastName.toLowerCase().includes(searchText)
              || where.Email.toLowerCase().includes(searchText)));

          this.noRecordFound = this.clients.length < 1 ? true : false;
        } else {
          this.clients = clients.filter(where => where.IsActive == false &&
            (where.FirstName.toLowerCase().includes(searchText) || where.LastName.toLowerCase().includes(searchText)
              || where.Email.toLowerCase().includes(searchText)));

          this.noRecordFound = this.clients.length < 1 ? true : false;
        }
      } else {
        if (this.selectedRole) {
          this.clients = clients.filter(where => where.IsActive == false && where.Role == this.selectedRole);

          this.noRecordFound = this.clients.length < 1 ? true : false;
        } else {
          this.clients = clients.filter(where => where.IsActive == false);

          this.noRecordFound = this.clients.length < 1 ? true : false;
        }
      }
    }
    if (this.selectedStatus == null || this.selectedStatus == '') {
      if (searchText.length > 0) {
        if (this.selectedRole) {
          this.clients = clients.filter(where => where.Role == this.selectedRole &&
            (where.FirstName.toLowerCase().includes(searchText) || where.LastName.toLowerCase().includes(searchText)
              || where.Email.toLowerCase().includes(searchText)));

          this.noRecordFound = this.clients.length < 1 ? true : false;
        } else {
          this.clients = clients.filter(where => (where.FirstName.toLowerCase().includes(searchText) || where.LastName.toLowerCase().includes(searchText)
            || where.Email.toLowerCase().includes(searchText)));

          this.noRecordFound = this.clients.length < 1 ? true : false;
        }
      }
      else {
        if (this.selectedRole) {
          this.clients = clients.filter(where => where.Role.toLowerCase() == this.selectedRole.toLowerCase());

          this.noRecordFound = this.clients.length < 1 ? true : false;
        } else {
          this.clients = clients;
        }
      }
    }
  }

  viewCompanyConfig(client: any) {
    var routerUrl = '/docuware-migration-subscription/' + client.ClientId;

    this.router.navigateByUrl(routerUrl);
  }

  openDocumentDeletePopup(client: any) {
    
    this.DeletePopup = true;
    this.deleteClient = client;
  }

  closeDeletePopup() {
    // $('.add-update-popup').fadeOut('fast');
    this.DeletePopup = false;
    this.deleteClient = new MigrationClientResponseModel;
  }

  onDeleteCompany() {
    if (!this.deleteClient) {
      return;
    }

    this.migrationClientService.deleteMigrationClient(this.deleteClient.ClientId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<any>(result, () => {
          
          if (result.IsSuccess) {
            

            this.timeoutFunct(this.deleteClient.FirstName + ' ' + this.deleteClient.LastName + ' deleted successfully.', true);
            this.getAllMigrationClients();
            this.closeDeletePopup();

          }
          else {
            if (result.Message.includes('Can not delete migration client ')) {
              this.timeoutFunct(result.Message + ' ' + this.deleteClient.FirstName + ' ' + this.deleteClient.LastName, false);
            } else {
              this.timeoutFunct(result.Message, false);
            }
            this.loading = false;
            this.closeDeletePopup();
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.closeDeletePopup();
        this.timeoutFunct(httpResponseError.message, false);
      });
  }
  ClosePopup(event: any) {
    this.manageClient = false;
    this.selectedClientId = 0;
    this.searchText = '';
  }
  clientListUpdated(event: any) {
    
    this.populateClientList();
    this.closeMessagePopup();
    this.ClosePopup(event);
  }

  ResendPasswordLinkPopup(client: MigrationClientResponseModel) {
    this.ResendLink = true;
    this.selectedUser = client;
    this.selectedEmail = client.Email;
  }

  cancelSendingLink() {
    this.ResendLink = false;
  }

  sendLink() {
    this.loading = true;
    this.migrationClientService.resendPasswordResetLink(this.selectedUser.ClientId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false))).subscribe(
        (result: any) => {

          this.processResult<any>(result, () => {
            if (result.IsSuccess) {
              this.ResendLink = false;
              this.getAllMigrationClients();
              this.timeoutFunct('Email sent successfully to ' + this.selectedUser.Email, true);
            }
            else {
              this.loading = false;
              this.ResendLink = false;
              this.timeoutFunct(result.Message, false);
            }
          });
        }
      )
  }
}
